import { UON, headers, operator, setOtherFilters } from "@/utils";
import { api } from "@/auth/api";
import { settings } from "@/settings";
import { QcHeader } from "@/types";

// Constants for user and configuration system UIDs
const odm_user = UON.SystemUid.odmUser;
const odmConfiguration = UON.SystemUid.odmConfiguration;

/**
 * Fetches role menus from the API.
 * @returns {Promise<any>} The data containing role menus.
 */
export async function fetchRoleMenus() {
  return (
    await api().get(settings.api_query_url, {
      headers: headers({
        qc: JSON.stringify({
          queryId: "role-menu-authority",
          offset: 0,
          limit: -1,
        }),
      }),
    })
  ).data;
}

/**
 * Processes items by grouping them by ID and adding modificationDate and modifiedBy.
 * @param {any[]} items - The items to process.
 * @returns {any[]} The processed items grouped by ID.
 */
export function processItems(items: any) {
  // Group by ID
  const groupedItems = items.reduce((acc: any, item: any) => {
    const existingItem = acc.find((existing: any) => existing.id === item.id);

    // Ensure all relevant dates are handled properly, falling back to creationDate if needed
    const modificationDates = [
      item.user_user_authority_modificationdate,
      item.user_group_authority_modificationdate,
    ].filter(date => date); // Remove nulls/undefined

    // If no modification dates, use creationDate as modificationDate
    const latestModificationDate = modificationDates.length > 0
      ? modificationDates.reduce((latest, date) => (date > latest ? date : latest), modificationDates[0])
      : item.creationdate; // Default to creationDate if no modification dates are found

    // Ensure modifiedBy has a fallback to createdBy
    const modifiedBy = item.modifiedby ? item.modifiedby : item.createdby;

    if (existingItem) {
      // Update the existing item with the latest modificationDate if it's newer
      existingItem.modificationDate = existingItem.modificationDate && existingItem.modificationDate > latestModificationDate
        ? existingItem.modificationDate
        : latestModificationDate;

      // Update modifiedBy only if the modificationDate was updated
      if (existingItem.modificationDate === latestModificationDate) {
        existingItem.modifiedBy = modifiedBy;
      }
    } else {
      // Add the item with modificationDate and modifiedBy
      acc.push({
        ...item,
        modificationDate: latestModificationDate,
        modifiedBy: modifiedBy,
      });
    }

    return acc;
  }, [] as any);

  return groupedItems;
}

/**
 * Fetches security groups from the API.
 * @returns {Promise<any>} The data containing security groups.
 */
export async function fetchSecurityGroups() {
  return (
    await api().get(settings.api_query_url, {
      headers: headers({
        qc: JSON.stringify({
          queryId: "get-security-groups",
          offset: 0,
          limit: -1,
        }),
      }),
    })
  ).data;
}

/**
 * Fetches group authorities by the provided code.
 * @param {string} authority_code - The authority code to search for.
 * @returns {Promise<any>} The data containing group authorities.
 */
export async function fetchGroupsAuthoritiesByCode(authority_code: string) {
  const qc_header: QcHeader = {
    qc: {
      queryId: "user-group-authority-by-code",
      offset: 0,
      limit: -1,
    },
  };

  const operators: any[] = [];

  operators.push(
    operator({
      val: authority_code,
      table: "user_group_authority",
      dbField: "authority_code",
    }).LIKE
  );

  setOtherFilters(operators, qc_header);

  const { data } = await api().get(settings.api_query_url, {
    headers: headers({ qc: JSON.stringify(qc_header.qc) }),
  });
  return data;
}

/**
 * Fetches translations by the provided key.
 * @param {string} key - The translation key to search for.
 * @returns {Promise<any>} The data containing translations.
 */
export async function fetchTranslationsBykey(key: string) {
  const qc_header: QcHeader = {
    qc: {
      queryId: "translation-value-by-key",
      offset: 0,
      limit: -1,
    },
  };

  const operators: any[] = [];

  operators.push(
    operator({
      val: `main.menu.${key}`,
      table: "configuration_translation",
      dbField: "key",
    }).LIKE
  );

  setOtherFilters(operators, qc_header);

  const { data } = await api().get(settings.api_query_url, {
    headers: headers({ qc: JSON.stringify(qc_header.qc) }),
  });
  return data;
}

/**
 * Adds a group authority to a security group.
 * @param {string} authority_code - The authority code to assign.
 * @param {string} group_id - The ID of the group.
 * @returns {Promise<any>} The result of the API request.
 */
export async function addGroupAuthority(authority_code: string, group_id: string) {
  const baseUrl = `${settings.api_url}/${odm_user}/api/1/${odm_user}/securitygroup/${group_id}/groupauthority/`;
  const result = await api().post(`${baseUrl}`, {
    objectType: "odm.user.groupauthority",
    systemUid: odm_user,
    authority: {
      objectType: "odm.user.authority",
      systemUid: odm_user,
      resourceUid: authority_code,
    },
  });

  return result;
}

/**
 * Deletes a group authority by ID.
 * @param {string} id - The ID of the group authority to delete.
 * @returns {Promise<any>} The result of the API request.
 */
export async function deleteGroupAuthority(id: string) {
  const baseUrl = `${settings.api_url}/${odm_user}/api/1/${odm_user}/groupauthority/${id}/`;
  const result = await api().delete(`${baseUrl}`);
  return result;
}

/**
 * Fetches user authorities by the provided code.
 * @param {string} authority_code - The authority code to search for.
 * @returns {Promise<any>} The data containing user authorities.
 */
export async function fetchUserAuthoritiesByCode(authority_code: string) {
  const qc_header: QcHeader = {
    qc: {
      queryId: "user-user-authority-by-code",
      offset: 0,
      limit: -1,
    },
  };

  const operators: any[] = [];

  operators.push(
    operator({
      val: authority_code,
      table: "user_user_authority",
      dbField: "authority_code",
    }).LIKE
  );

  setOtherFilters(operators, qc_header);

  const { data } = await api().get(settings.api_query_url, {
    headers: headers({ qc: JSON.stringify(qc_header.qc) }),
  });
  return data;
}

/**
 * Adds a user authority for a specific user.
 * @param {string} authority_code - The authority code to assign.
 * @param {string} user_id - The ID of the user.
 * @returns {Promise<any>} The result of the API request.
 */
export async function addUserAuthority(authority_code: string, user_id: string) {
  const baseUrl = `${settings.api_url}/${odm_user}/api/1/${odm_user}/leaseforgeuser/${user_id}/userauthority/`;
  const result = await api().post(`${baseUrl}`, {
    objectType: "odm.user.userauthority",
    systemUid: odm_user,
    authority: {
      objectType: "odm.user.authority",
      systemUid: odm_user,
      resourceUid: authority_code,
    },
  });

  return result;
}

/**
 * Deletes a user authority by ID.
 * @param {string} id - The ID of the user authority to delete.
 * @returns {Promise<any>} The result of the API request.
 */
export async function deleteUserAuthority(id: string) {
  const baseUrl = `${settings.api_url}/${odm_user}/api/1/${odm_user}/userauthority/${id}/`;
  const result = await api().delete(`${baseUrl}`);
  return result;
}

/**
 * Saves a new role name in the system.
 * @param {string} roleName - The name of the new role.
 * @returns {Promise<string>} The resource UID of the newly created role.
 */
export async function saveNewRoleName(roleName: string) {
  const baseUrl = `${settings.api_url}/${odm_user}/api/1/${odm_user}/authority/`;
  const result = (
    await api().post(`${baseUrl}`, {
      objectType: "odm.user.authority",
      systemUid: odm_user,
      resourceUid: "ROLE_MENU_" + roleName,
    })
  ).data?.resourceUid;
  return result;
}

/**
 * upload translation.
 * @param {string} lang key: any , value: any - The name of the new role.
 * @returns {Promise<string>} The resource UID of the newly created role.
 */
export async function uploadTranslation(lang: string , key: any , value: any){
  const baseUrl = `${settings.api_url}/${odmConfiguration}/api/1/${odmConfiguration}/translation/upload/`;
  const result = await api().post(`${baseUrl}`, {
    locale: `${lang}-Us`,
    content: {
      [`main.menu.${key}`]: value 
    },
  })
  return result;
}
