import { api } from "@/auth/api";
import store from "@/store";
import TemplatesDataMock from "@/store/services/document/mock/templatesMock.json"
import { Template } from "@/types";
export async function getTemplates(
    system_uid: string,
    path: string,
    resourceUid?: string
) {
    const url = `/${system_uid}/api/1/${system_uid}/${path}/${resourceUid}/`;
    return (await api().get(url)).data;
    //return TemplatesDataMock
}


export async function reprocessDocumentGeneration(system_uid: string,
    path: string, template: Template) {
    const url = `/${system_uid}/api/1/${system_uid}/${path}/`;


    const payload = {
        reportFormat: "PDF",
        offerId: template.entityId,
        offerReference: store.state.taskModule.offer?.reference,
        reports: [
            {
                packageName: template.packageName,
                signatureType: template.signatureType,
                templates: [
                    {
                        documentOrder: template.documentOrder,
                        fileName: template.fileName,
                        params: [
                            {
                                fileCode: template.templateName,
                                partyId: null,
                                resourceDocumentId: template.resourceUid
                            }
                        ]
                    }
                ]
            }
        ]
    }

    return (await api().post(url, payload)).data;
} 