import { Module } from "vuex";
import { MiddleOfficeState, RootState } from "@/types";
import { getOffer } from "@/store/services/offerService";
import Notify from 'quasar/src/plugins/Notify.js';;
import { getGatheringProcessList } from "@/store/services/task/actions/validateDocumentService";
import {
  buildClassification,
  getOfferDecision,
  getTaskHistory,
  buildClassificationHistory,
  getPartyClassification,
  getRelationList,
  getListPreCondition,
} from "@/store/services/middle/middleOfficeService";
import { getEntity, getOfferInfo, getSalesPerson, Paths, UON } from "@/utils";
import store from "@/store";
import { useApi } from "@/requests/useApi";

const middleOfficeModule: Module<MiddleOfficeState, RootState> = {
  namespaced: true,
  state: {
    showSummary: false,
    summaryPanel: false,
    variablesVisible: false,
    openopeAnalysisReference: false,
    analysisReference: "",
    calculate: {},
    preConditionDetail: {},
    preConditionDetailVisible: false,
  },
  getters: {
    showSummary(state) {
      return state.showSummary;
    },
    offer(state) {
      return state.offer;
    },
    getProposalGuarantees(state) {
      return state.offer?.proposals[0].proposalItems[0].proposalGuarantees;
    },
    getProposalGuaranteeSelected(state) {
      return state.ProposalGuaranteeSelected;
    },
    getSumofInsurance(state) {
      return state.calculate.sumOfInterests;
    },
  },
  actions: {
    searchClassification({ commit }, payload) {
      const { filter, callback } = payload;
      getPartyClassification(filter)
        .then((res) => {
          callback(res);
        })
        .catch((e) => console.error(e));
    },
    initState({ commit }, payload) {
      const { offerId } = payload;
      store.dispatch("setSummaryAvailable", false);
      if (!offerId) {
        throw new Error("OfferId is required");
      }
      getOffer(offerId, this.state, true)
        .then(async (response) => {
          const { offer, proposalScale } = response;
          commit("setProposalScale", proposalScale);
          await getSalesPerson(offer);
          commit("setOffer", offer);
          store.dispatch("setSummaryAvailable", true);
          //dispatch the current context with the offer that was retrieved from the server
          this.dispatch("setCurrentContext", offer);
          //-------------------------------------
          getGatheringProcessList(offerId)
            .then((res) =>
              commit(
                "setGatheringProcessList",
                this.state.taskModule.gatheringProcess
              )
            )
            .catch((e) => console.error(e));
          commit("setshowService", false);
          const offerInfo = getOfferInfo(offer);
          store.dispatch("demandModule/setPartyInfo", offerInfo);
          store.dispatch("demandModule/setQuoteInfo", offerInfo);
          store.dispatch("demandModule/setSignatureMethod", offer);
          if (payload.callback) payload.callback(offer);
        })
        .catch((err) => {
          console.error(err);
          Notify.create({
            timeout: 10000,
            actions: [{ icon: "close", color: "white" }],
            message: err.message || err.error || err,
            color: "negative",
          });
        });
    },
    buildClassification({ commit }, payload) {
      const { party, callback } = payload;
      const filter = { id: party.resourceUid };

      buildClassification(filter)
        .then(async (classifications) => {
          if (callback) {
            callback(classifications);
          }
        })
        .catch((err) => {
          Notify.create({
            timeout: 10000,
            actions: [{ icon: "close", color: "white" }],
            message: `Error: \n ${err}`,
            color: "negative",
          });
        });
    },
    setDetailPreconditionVisible({ commit }, payload) {
      commit("setDetailPreconditionVisible", payload);
    },
    setDetailPrecondition({ commit }, payload) {
      commit("setDetailPrecondition", payload);
    },
    buildClassificationHistory({ commit }, payload) {
      const { filter, callback } = payload;
      buildClassificationHistory(filter)
        .then(async (classifications) => {
          if (callback) {
            callback(classifications);
          }
        })
        .catch((err) => {
          Notify.create({
            timeout: 10000,
            actions: [{ icon: "close", color: "white" }],
            message: `Error: \n ${err}`,
            color: "negative",
          });
        });
    },

    setCalculationSimulation({ commit }, payload) {
      commit("setCalculationSimulation", payload);
    },

    showSection({ commit }, payload) {
      const { section } = payload;
      commit("setSection", section);
    },
    showService({ commit }, payload) {
      const { showService } = payload;
      commit("setshowService", showService);
    },
    getTaskHistory({ commit }) {
      getTaskHistory()
        .then((response) => {
          commit("setHistoriesStep", response);
        })
        .catch((err) => {
          console.error(err);
          Notify.create({
            timeout: 10000,
            actions: [{ icon: "close", color: "white" }],
            message: err.message || err.error || err,
            color: "negative",
          });
        });
    },

    getListPreCondition({ commit }) {
      getListPreCondition()
        .then((response) => {
          commit("setPreConditionStep", response);
        })
        .catch((err) => {
          console.error(err);
          Notify.create({
            timeout: 10000,
            actions: [{ icon: "close", color: "white" }],
            message: err.message || err.error || err,
            color: "negative",
          });
        });
    },
    getRelationList({ commit }, payload) {
      const { party, callback } = payload;
      getRelationList(party)
        .then((response) => {
          callback(response);
        })
        .catch((err) => {
          console.error(err);
          Notify.create({
            timeout: 10000,
            actions: [{ icon: "close", color: "white" }],
            message: err.message || err.error || err,
            color: "negative",
          });
        });
    },
    getListDecision({ commit }, payload) {
      getOfferDecision(payload)
        .then((response) => {
          commit("setDecisionStep", response);
        })
        .catch((err) => {
          console.error(err);
          Notify.create({
            timeout: 10000,
            actions: [{ icon: "close", color: "white" }],
            message: err.message || err.error || err,
            color: "negative",
          });
        });
    },
    viewProposalGuarantee({ commit }, payload) {
      commit("setProposalGuaranteeSelected", payload);
    },
    setVariablesVisible({ commit }, payload) {
      commit("setVariablesVisible", payload);
    },
    setStatusHistoryVariablesSelected({ commit }, payload) {
      commit("setStatusHistoryVariablesSelected", payload);
    },
    showAnalysisReferenceDetails({ commit }) {
      commit("showAnalysisReferenceDetails");
    },
    hideAnalysisReferenceDetails({ commit }) {
      commit("hideAnalysisReferenceDetails");
    },
    setanalysisReference({ commit }, payload) {
      commit("setanalysisReference", payload);
    },
    setOfferDetailPreconditionVisible({ commit }, payload) {
      commit("setOfferDetailPreconditionVisible", payload);
    },
    setOfferDetailPrecondition({ commit }, payload) {
      commit("setOfferDetailPrecondition", payload);
    },
  },
  mutations: {
    setProposalScale(state, payload) {
      state.proposalScale = payload;
    },
    setOffer(state, payload) {
      state.offer = payload;
      state.showSummary = false;
    },
    setSection(state, payload) {
      state.section = payload;
    },
    setshowService(state, payload) {
      state.showService = payload;
    },
    setHistoriesStep(state, payload) {
      state.historiesStep = payload;
    },
    setGatheringProcessList(state, payload) {
      state.gatheringProcessList = payload;
    },
    setDecisionStep(state, payload) {
      state.decisionStep = payload;
    },
    setPreConditionStep(state, payload) {
      state.preConditionStep = payload;
    },
    setProposalGuaranteeSelected(state, payload) {
      state.ProposalGuaranteeSelected = payload;
    },
    setDetailPreconditionVisible(state, payload) {
      state.preConditionDetailVisible = payload;
    },
    setDetailPrecondition(state, payload) {
      state.preConditionDetail = payload;
    },
    setVariablesVisible(state, payload) {
      state.variablesVisible = payload;
    },
    setStatusHistoryVariablesSelected(state, payload) {
      state.statusHistoryVariableSelected = payload;
    },
    showAnalysisReferenceDetails(state) {
      state.openopeAnalysisReference = !state.openopeAnalysisReference;
    },
    hideAnalysisReferenceDetails(state) {
      state.openopeAnalysisReference = false;
    },
    setanalysisReference(state, payload) {
      state.analysisReference = payload;
    },
    setCalculationSimulation(state, payload) {
      state.calculate = payload;
    },
    setOfferDetailPreconditionVisible(state, payload) {
      state.preConditionDetailVisible = payload;
    },
    setOfferDetailPrecondition(state, payload) {
      state.preConditionDetail = payload;
    },
  },
};

export default middleOfficeModule;
