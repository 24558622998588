import store from "@/store";
import axios from "axios";
import { TaskSearchCriteria, Task, QcHeader } from "@/types";
import {  getDecision } from "../task/actions/decisionService";
import { getTaskHistoryByEntity, getTaskUserAction } from "../task/workflow";
import { api } from "@/auth/api";
import { settings } from "@/settings";
import i18n from "@/i18n";
import { getOperator, globalConfig, headers } from "@/utils";
import { formatDateLocale } from "@/utils/configuration/formatters-config";
import { useApi } from "@/requests/useApi";
import { buildManagerRelation } from "@/commons/party-middleware";
import { buildRelations } from "../searchThird";
import { getCheckPointsGathering } from "../task/actions/order/completePreconditionsService";
import { getGatheringProcessList } from "../task/actions/validateDocumentService";
import { getOfferDocument, getProcess, getRequiredDocument } from "../task/actions/uploadDocumentService";
import { getAdditionalInformation } from "../picklist";
import moment from "moment";

const odm_user = `odm-user`
const api_query_url = settings.api_query_url;

export async function getTaskHistory() {
  store.state.middleOfficeModule.offer?.resourceUid || "";
  const taskSearchCriteria: TaskSearchCriteria = {
    entityId: store.state.middleOfficeModule.offer?.resourceUid || "",
    initializeVariables: 'offerReference;offerStatus;offerPhase;offerCreationDate;customerReference;customerName;customerType;_clientDecisionStatus;additional_action_items;SignatureMethod;proposalReference'
  };
  const tasklist = await getTaskHistoryByEntity(taskSearchCriteria);
  const Result = await setTaskUserAction(tasklist.data);
  return Result;
}
export async function setTaskUserAction(payload: any) {
  payload.forEach(async (element: Task) => {
    if (element.taskId) {
      element.histories = await (await getTaskUserAction(element.taskId)).data;
      element.opened = true;
    }
  });

  return payload.sort((a: any, b: any) => Date.parse(b.creationDate) - Date.parse(a.creationDate));
}

export async function getOfferDecision(listDecision: any) {
  const Result = await setListDecision(listDecision);
  return Result;
}

export async function setListDecision(listDecision: any) {
  const decisionList: any[] = []
  let decisionAnalysis: any[] = []
  let decisionOpinions: any[] = []
  let opinionAnalysisProcessReference: any;
  let opinionAnalysisProcessRessourceUid: any;

  for (let i = listDecision.length - 1; i >= 0; i--) {
    const decision = await getDecision(listDecision[i].decision.resourceUid);
    if (decision.decisionMakerId?.resourceUid) {
      const user = await getUser(decision.decisionMakerId.resourceUid)
      user.userGroups.forEach((userGroup: any) =>
        decision.decisionMakerProfil = (decision.decisionMakerProfil ? decision.decisionMakerProfil.concat(' || ') : '') + i18n.global.t("middleOffice.decision.profil.".concat(userGroup?.group?.resourceUid)));
      decision.decisionMaker = user.firstName + ' ' + user.lastName
    }
    decisionAnalysis = await getDecisionAnalysis(decision.resourceUid)

    decisionOpinions = await getDecisionOpinions(decision.resourceUid)

    if (decision.associatedAnalysisProcess.length > 0) {
      for (let x = 0; x < decision.associatedAnalysisProcess.length; x++) {
        const analysisProcess = decisionAnalysis
          .find((item: any) => item.analysis_process_resourceuid === decision.associatedAnalysisProcess[x].analysisProcess.resourceUid
            && item.decision_process_id === decision.resourceUid
          )
        if (analysisProcess) {
          let firstname: string
          let lastname: string
          analysisProcess.reference ? decision.associatedAnalysisProcess[x].analysisProcessReference = analysisProcess.reference : decision.associatedAnalysisProcess[x].analysisProcessReference = ''
          analysisProcess.analysis_process_resourceuid ? decision.associatedAnalysisProcess[x].analysisProcessResourceUid = analysisProcess.analysis_process_resourceuid : decision.associatedAnalysisProcess[x].analysisProcessResourceUid = ''
          if (analysisProcess.firstname === null || analysisProcess.firstname === "") {
            firstname = "-"
          }
          else {
            firstname = analysisProcess.firstname
          }
          if (analysisProcess.lastname === null || analysisProcess.lastname === "") {
            lastname = "-"
          }
          else {
            lastname = analysisProcess.lastname
          }
          decision.associatedAnalysisProcess[x].analysisMaker = firstname + ' ' + lastname
          decision.associatedAnalysisProcess[x].analysisDate = analysisProcess._when_created
          if (x === decision.associatedAnalysisProcess.length - 1) {
            opinionAnalysisProcessReference = decision.associatedAnalysisProcess[x].analysisProcessReference
            opinionAnalysisProcessRessourceUid = decision.associatedAnalysisProcess[x].analysisProcessResourceUid
          }


        }

      }
    }
    if (decision.opinions.length > 0) {
      for (let j = 0; j < decision.opinions.length; j++) {
        const analysisProcessOpinion = decisionOpinions
          .find((item: any) => item.decision_opinion_maker_id === decision.opinions[j].resourceUid
            && item.decision_process_id === decision.resourceUid
          )
        if (analysisProcessOpinion) {
          let firstname: string
          let lastname: string
          if (analysisProcessOpinion.firstname === null || analysisProcessOpinion.firstname === "") {
            firstname = "-"
          }
          else {
            firstname = analysisProcessOpinion.firstname
          }
          if (analysisProcessOpinion.lastname === null || analysisProcessOpinion.lastname === "") {
            lastname = "-"
          }
          else {
            lastname = analysisProcessOpinion.lastname
          }
          decision.opinions[j].opinionMaker = firstname + ' ' + lastname
          decision.opinions[j].opinionDate = analysisProcessOpinion._when_created
          decision.opinions[j].opinionAnalysisProcessReference = opinionAnalysisProcessReference
          decision.opinions[j].opinionAnalysisProcessRessourceUid = opinionAnalysisProcessRessourceUid

        }
      }
    }
    decisionList.push(decision)

  }
  return decisionList
}

export async function getUser(userResourceUid: string) {
  const result = await api().get(`${settings.api_url}/${odm_user}/api/1/${odm_user}/leaseforgeuser/${userResourceUid}/`);
  return result.data
}
export async function getSecuritygroup(groupResourceUid: string) {
  const result = await api().get(`${settings.api_url}/${odm_user}/api/1/${odm_user}/securitygroup/${groupResourceUid}/groupauthority/`);
  return result.data
}
export async function buildClassification(filter: any) {

  const partyClassification = await getPartyClassification(filter)
  const classifications = []
  for (const classification of partyClassification) {
    classifications.push({
      createdBy: classification.first_name ? classification.first_name + ' ' + classification.last_name : classification._who_created,
      classification_date: classification.classification_date ? formatDateLocale(classification.classification_date, i18n.global.t("mask.format_en_input"), 'fr', 'DD/MM/YYYY') : '',
      validity_from: classification.validity_from ? formatDateLocale(classification.validity_from, i18n.global.t("mask.format_en_input"), 'fr', 'DD/MM/YYYY') : '',
      validity_until: classification.validity_until ? formatDateLocale(classification.validity_until, i18n.global.t("mask.format_en_input"), 'fr', 'DD/MM/YYYY') : '',
      partyClass: classification.id,
      class_type_code: classification.class_type_code,

    })
  }

  const classificationMO = classificationsMO(classifications)
  const ListClassificationMO = listeClassType(classificationMO)
  return ListClassificationMO;
}
export async function buildClassificationHistory(filter: any) {

  const partyClassification = await getPartyClassification(filter)
  const classifications = []
  for (const classification of partyClassification) {
    classifications.push({
      createdBy: classification.first_name ? classification.first_name + ' ' + classification.last_name : classification._who_created,
      classification_date: classification.classification_date,
      validity_from: classification.validity_from,
      validity_until: classification.validity_until,
      partyClass: classification.id,
      class_type_code: classification.class_type_code,

    })
  }
  return classifications;
}


export function getPartyClassification(filter: any): Promise<any[]> {
  return new Promise<any[]>(async (resolve, reject) => {
    const url = settings.api_query_url;

    const qc_header: any = {
      qc: {
        queryId: 'party-party-classification',
      }
    }
    qc_header.qc.parameters = {
      ...filter
    }
    const operators = []
    if (filter && filter.id != null) {
      operators.push(getOperator("party_party_classification", "party_id", "EQUAL", globalConfig.formatters.getEqualValue(filter.id), "party_id"))
    }
    if (filter && filter.class_type_code != null) {
      operators.push(getOperator("party_party_class", "class_type_code", "EQUAL", globalConfig.formatters.getEqualValue(filter.class_type_code), "class_type_code"))
    }

    if (operators.length > 0) {
      qc_header.qc.otherFilters = {
        expressions: [
          {
            and: {
              operators
            }
          }
        ]
      }
      qc_header.qc.otherOrders = null
      qc_header.qc.offset = 0
      qc_header.qc.limit = 50
    }

    const _headers = headers({
      qc: JSON.stringify(qc_header.qc)
    })

    axios.get(url, {
      headers: _headers
    }).then((res: any) => {
      resolve(res.data)
    }).catch((err: any) => reject(err));
  })
}

function classificationsMO(classifications: any) {
  const classificationsSort = classifications.sort((a: any, b: any) => new Date(b.classification_date).getTime() - new Date(a.classification_date).getTime());
  return classificationsSort;
}

function listeClassType(classifications: any) {
  const classTypes = new Set<string>();
  const objetsDistincts = classifications.filter((classification: any) => {
    if (!classification.class_type_code || classTypes.has(classification.class_type_code)) {
      return false;
    }
    classTypes.add(classification.class_type_code);
    return true;
  });

  return objetsDistincts;
}


export const getDecisionAnalysis = async (filter: any) => {
  const qc_header: QcHeader = {
    qc: {
      queryId: "decision-analysis-details",
      offset: 0,
      limit: 100
    }

  };

  const operators = []
  if (filter) {
    operators.push(getOperator("decision_associated_analysis_process", "decision_process_id", "EQUAL", globalConfig.formatters.getEqualValue(filter), "decision_process_id"))
  }
  if (operators.length > 0) {
    qc_header.qc.otherFilters = {
      expressions: [
        {
          and: {
            operators
          }
        }
      ]
    }

  }

  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  return data
}
export const getRelationList = async (person: any) => {
  const third = person.person ? person.person : person.organization 
  const partyRelations = (await buildManagerRelation(third ))?.load()
  if (partyRelations) {
    third.relations = buildRelations(partyRelations, third);
  }
  return third.relations
}


export const getDecisionOpinions = async (filter: any) => {
  const qc_header: QcHeader = {
    qc: {
      queryId: "decision-opinions-details",
      offset: 0,
      limit: 100
    }

  };

  const operators = []
  if (filter) {
    operators.push(getOperator("decision_opinion_maker", "decision_process_id", "EQUAL", globalConfig.formatters.getEqualValue(filter), "decision_process_id"))
  }
  if (operators.length > 0) {
    qc_header.qc.otherFilters = {
      expressions: [
        {
          and: {
            operators
          }
        }
      ]
    }

  }

  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  return data
}


export const getListPreCondition = async () => {
    const offerId=   store.state.middleOfficeModule.offer?.resourceUid
    if (offerId){
      const processList = await getOfferDocument(offerId)
      let listcheckPoint: any 
      let preConditionList: any
      let documentUpload = {};
      let receptionDate
      for (const process of processList) {
          const gatheringProcessUid = process.gatheringProcess.resourceUid
          if (gatheringProcessUid){
            const checkpointsgathering = await getCheckPointsGathering(gatheringProcessUid)
            if( checkpointsgathering && checkpointsgathering.length>0 ){

              listcheckPoint=[]
              for (const checkpointGath of checkpointsgathering){
                 preConditionList = []
                for (const checkpoint of checkpointGath.items) {  

                  if(checkpoint.objectType.includes('documentvalue')){

                  const  gatheringProcess = await getProcess(gatheringProcessUid)  
                  const requiredDocuments =    await getRequiredDocument(gatheringProcessUid)
                  for (const requiredDocument of requiredDocuments){
                    if(gatheringProcess.supportingDocuments.length===1){
                        requiredDocument.supportingDocumentUid =gatheringProcess.supportingDocuments[0].resourceUid
                    }else{
                        const supportingDocument = gatheringProcess.supportingDocuments.filter((s: any) => 
                        s.documentFamily.resourceUid === requiredDocument.supportingType[0].supportingDocument.resourceUid)[0];
                        if(!requiredDocument.supportingDocumentUid && supportingDocument){
                         requiredDocument.supportingDocumentUid =supportingDocument.resourceUid
                        }
                    }
                   
                    
                    const doc = gatheringProcess.gatheringProcessItem[0].receivedFiles.filter(
                        (s: any) => s.content[0].documentType.resourceUid === requiredDocument.resourceUid
                    );
                    if (doc.length > 0 ) {
                        documentUpload = {
                            format: "",
                            originalFileName: doc[doc.length - 1].content[0].label,
                            resourceUid: doc[doc.length - 1].fileReference.resourceUid,
                            objectType: doc[doc.length - 1].fileReference.objectType,
                            systemUid: doc[doc.length - 1].fileReference.systemUid,
                        };
                        receptionDate = doc[doc.length - 1].receptionDate
                      }

                    }
                  }
              
                      const filter= {
                            idCheck: checkpoint.resourceUid
                      }
                          const moreInfo = await  getAdditionalInformation(filter) 
                            let preCondition= {
                                      status : checkpoint.status.resourceUid,
                                      type : checkpoint.objectType,
                                      label : checkpoint.i18nLabel,
                                      actionDate: moreInfo.length > 0 ? moreInfo[0]._when_created :'',
                                      realisationDate: checkpoint.completionDate  ? moment(checkpoint.completionDate, "DD/MM/YYYY").format("DD/MM/YYYY HH:mm:ss") : (moment(receptionDate).isValid() ? moment(receptionDate).format("DD/MM/YYYY HH:mm:ss") : ''),
                                      user: moreInfo.length > 0 ? moreInfo[0].first_name+' '+moreInfo[0].last_name :'',
                                      fieldValue: checkpoint.fieldValue,
                                      checkComment: checkpoint.checkComment,
                                      document: {
                                          documentUpload :checkpoint.status && checkpoint.status.resourceUid === 'COMPLETED' ? documentUpload:{},
                                      }

                            }
                    
                      preConditionList.push(preCondition)
                   }
               listcheckPoint.push(preConditionList)
              }   
          }
        }
       
      }
      return  listcheckPoint
    
  }

}

