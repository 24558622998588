// Auth

import Party from "@/components/middle-office/tabs/Party.vue";
import { ProposalGuaranteeClass } from "@/store/services/financing/guarantee/domain/ProposalGuaranteeClass";
//Query Builder
import { SortableOptions } from "sortablejs";
import { ComponentInternalInstance } from "vue";

export interface User extends UniformOdmName {
  resourceUid?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  daaqList?: ComboBoxItem[];
  authorities?: any;
}

export interface AssociatedUser {
  user: User;
}

// Generic

export type TODO = any;

export interface UniformOdmName {
  objectType?: string;
  systemUid?: string;
  resourceUid?: string;
}

export type AcknowlodgeResponse = {
  message: string;
};

export interface Select extends UniformOdmName {
  type?: PicklistItem;
}

export type Picklist = {
  systemUid: string;
  items: PicklistItem[];
};
export interface EvaluateTaxRate extends UniformOdmName {
  resourceUid: string;
  objectType: string;
  systemUid: string;
  businessData: object;
  daaq: string;
  taxRate: number;
  effectiveDate: Date;
}
export type List = {
  items: OpinionItem[];
};

export type OpinionItem = {
  Approved: "APPROVED";
  Rejected: "REJECTED";
};

export type PicklistItem = {
  id: string | undefined;
  label: string;
  config?: any;
  selected?: boolean;
  ascendinglabel?: any;
  descendinglabel?: any;
};

export interface SelectComboBox extends UniformOdmName {
  type?: ComboBoxItem;
  classType?: SelectComboBox;
}

export interface ComboBox extends UniformOdmName {
  items: ComboBoxItem[];
  systemUid?: string;
  entityName?: string;
}

export interface ComboBoxItem {
  label?: string;
  value: string | any;
  config?: any;
}

export type Pagination = {
  page: number;
  totalItems: number;
  rowsPerPage: number;
  pages: number;
};

// State

export interface RootState {
  appSelectedLogoFile?: any; // Logo selected file object
  appLogoConfig?: any;
  appLogoBase64?:any;
  queryBuilderModule: QueryBuilderState;
  analysisModule?: AnalysisState;
  modifyConditionsModule: ModifyConditionsState;
  notificationModule?: Notifications;
  messageModule?: Message;
  module?: string;
  lockState?: boolean;
  confirmationDialogState?: ConfirmationDialogState;
  confirmationDeleteDialogState?: ConfirmationDeleteDialogState;
  toRefresh?: boolean;
  closeScale?: boolean;
  loading?: boolean;
  closeAvailability?: boolean;
  authModule?: AuthState;
  picklistModule: PicklistState;
  demandModule: DemandState;
  thirdModule: ThirdState;
  simulationModule: SimulationState;
  searchThirdModule: SearchPersonState;
  taskModule: TaskState;
  financingModule: FinancingState;
  middleOfficeModule: MiddleOfficeState;
  summaryPanelModule: SummaryPanelState;
  currentContext?: Offer | Task | Third;
  messagingModule?: any;
  scaleModule: ScaleState;
  garantyModule: GarantyState;
  assetModule: AssetsState;
  screnConfigurationModule: ScrenConfigurationState;
  availabilityModule: AvailabilityState;
  controlsModule: ControlsState;
  dossierModule: DossierState;
  tabledata?: TableState;
  commandModule?: CommandState;
  orderModule: OrderState;
  printDocumentModule: PrintDocumentState;
  userModule: any;
  orderCommissionModule: OrderCommissionState;
  filterModule: filterState;
  orderPartyModule: any;
  summaryAvailable: boolean;
  isLoading?: boolean;
  templateModule: TemplateState
}
// types/Template for file generation
export interface Template {
  resourceUid: string | null;
  objectType: string;
  systemUid: string;
  businessData: any | null;
  daaq: any | null;
  templateName: string;
  processId: string;
  entityId: string;
  statusPrint: string;
  signatureType: string;
  fileId: string | null;
  packageName: string,
  documentOrder: number,
  fileName: string
}

export interface TemplateState {
  templates: Template[];
  selectedTemplates: string[]; // Array of processIds
  previewTemplate: Template[];
  isLoading: boolean;
  error: string | null;
}
export interface FileResponse {
  content: string;
  format: string;
  originalFileName: string;
}

export interface DownloadRequest {
  resourceUid: string;
}
export interface GarantyState {
  selectedGaranty?: any;
  openGaranty: boolean;
  myAction?: boolean;
  selectedgarantydetails?: GarantieDetails;
  selectedguarantor?: Third;
  mode?: string;
  reloadListGaranty: string;
  validField: boolean;
  saveAuthorized: boolean;
  isSaving?: boolean;
}
export interface ScrenConfigurationState {
  listConfiguration?: any;
}
export interface ScrenConfigurationState {
  listConfiguration?: any;
}
export interface GarantySearch {
  guaranty_name?: string;
  type?: ComboBoxItem;
  status?: ComboBoxItem;
  reference?: string;
  checked?: boolean;
}
export interface OfferGuarantee {
  productReference?: string;
  categoryClass?: ComboBoxItem;
  productName?: ComboBoxItem;
  productStatus?: string;
  checked?: boolean;
}
export interface AssetsState {
  viewMode?: boolean;
  selectedAsset?: GoodDetails;
  isLoading?: boolean;
  openAsset: boolean;
  selectedItems: GoodCatalogsSearch[];
  tempSelectedGoods: AssetSearch[];
  selectedCatalogs: GoodCatalogsSearch[];
  tempSelectedCatalogs: GoodCatalogsSearch[];
  validField: boolean;
  isEditMode?: boolean;
}

export interface GoodDetails extends DAAQ {
  reference?: string;
  type?: SelectComboBox;
  nbOfDoor?: number;
  nbOfSeat?: number;
  nbOfCylinder?: number;
  nbOfGear?: number;
  taxHorsePower?: number;
  horsePower?: number;
  powerPhp?: number;
  co2Emissions?: number;
  mineType?: number;
  fuelConsumptionUrban?: number;
  fuelConsumptionExtraUrban?: number;
  fuelConsumptionCombined?: number;
  millage?: Millage;

  validity?: Validity;
  status?: SelectComboBox | string;
  classifications?: Classifications[];
  name?: string;
  qualifier?: Qualifier;
  tax?: any;
  feature?: Feature[];
  objectType?: string;
  systemUid?: string;
  resourceUid?: string;
  businessData?: any;
  inventoryItems?: any[];
  images?: any;
  brand?: any;
  version?: any;
  range?: any;
  carBodyStyle?: any;
  engineType?: any;
  family?: any;
  subFamily?: any;
  goodOption?: GoodProposalItem[];
  goodAccessory?: GoodProposalItem[];
  goodFees?: GoodProposalItem[];
  optionItems?: GoodProposalItem;
  AccessoryItems?: GoodProposalItem;
  FeesItems?: GoodProposalItem;
  residualValue?: GoodResidualValue;
  goodCatalogs?: GoodCatalog;
  packid?: string;
  selectedGoods?: AssetSearch[];
  selectedCatalogs?: GoodCatalogsSearch[];
  /// inventory
  inventory?: boolean;
  guarantee?: Validity;
  constructionDate?: any;
  serialNumber?: any;
  matriculationDate?: any;
  matriculation?: any;
}

export interface GoodCatalog {
  goodCatalogCreation?: GoodCatalogCreation;
  goodCatalogsSearch?: GoodCatalogsSearch[];
}

export interface GoodCatalogCreation {
  provider?: UniformOdmName;
  reference?: string;
  description?: string;
  validity?: Validity;
  supplier?: Third;
}
export interface GoodCatalogsSearch {
  catalogPrice: string;
  currency: string;
  resourceUid: any;
  price: string;
  catalogcode: any;
  reference?: string;
  name?: string;
  creationDate?: string;
  status?: string;
}
export interface ClassificationsAsset {
  category: string;
  categoryClass: string;
  validity: Validity;
}

export interface GoodResidualValue {
  minRv?: number;
  maxRv?: number;
  defaultRv?: number;
  advancedConfigurationToggle?: boolean;
}

export interface GoodProposalItem {
  index?: number;
  nameItem?: ComboBoxItem;
  required?: boolean;
  priceWoTax?: number;
  discount?: number;
  tax?: number;
  typeTax?: string;
  status?: string;
  validity?: Validity;
  reference?: string;
  classifications?: any;
  residualValue: any;
}

export interface ScaleState {
  isLoading?: boolean;
  selectedScale?: any;
  openScale: boolean;
  scaleFilterRef: string;
  scaleManagement: ScaleManagement;
  deletedClassificationUid?: string | undefined;

  inputHeaders?: InputHeader[];
}

export interface ConfirmationDialogState {
  show?: boolean;
  message?: string;
  target?: string;
  confirmation?: string;
  confirmButton?: string;
  action?: string;
  payload?: any;
}
export interface ConfirmationDeleteDialogState {
  show?: boolean;
  message?: string;
  target?: string;
  payload?: object;
  deleteAction?: string;
}
export interface PicklistState {
  typeService?: Picklist;
  namesServices?: Picklist;
  demandStatus?: Picklist;
  brand?: Picklist;
  range?: Picklist;
  baseCalcule?: Picklist;
  clientType?: Picklist;
  network?: Picklist;
  networkNode?: Picklist;
  role?: ComboBox;
  term?: Picklist;
  paymentDate?: Picklist;
  paymentFrequence?: Picklist;
  financingProduct?: Picklist;
  activitySector?: ComboBox;
  partyClassificationType?: ComboBox;
  legalCategory?: ComboBox;
  relations?: ComboBox;
  countries?: Picklist;
  geographicZone?: ComboBox;
  cities?: Picklist;
  postalCodes?: ComboBox;
  statusMarital?: ComboBox;
  matrimonialRegime?: ComboBox;
  addressType?: ComboBox;
  addressPreferenceType?: ComboBox;
  titles?: ComboBox;
  occupation?: Picklist;
  periodRateOption?: Picklist;
  warrantMethod?: Picklist;
  contactType?: Picklist;
  IdProof?: Picklist;
  facilityType?: ComboBox;
  relationType?: ComboBox;
  accountType?: Picklist;
  opinion?: List;
  accountStatus?: Picklist;
  natureBalanceSheet?: Picklist;
  roleStatus?: ComboBox;
  partyClass?: ComboBox;
  nationality?: ComboBox;
  jobActivity?: ComboBox;
  jobPositionType?: ComboBox;
  jobOccupationType?: ComboBox;
  nature?: ComboBox;
  periodicity?: ComboBox;
  current_status_code?: Picklist;
  partyRelationType?: ComboBox;
  language?: ComboBox;
  typeOutstanding?: ComboBox;
  financialInstitution?: ComboBox;
  gender?: ComboBox;
  contractType?: ComboBox;
  propertyStatus?: ComboBox;
  documentType?: ComboBox;
  codeNaceOptions?: ComboBox;
  signatureMethods?: ComboBox;
  //Asset
  categoryAsset?: Picklist;
  usageAsset?: ComboBox;
  colors?: ComboBox;
  statusOrder?: ComboBox;
  activity?: ComboBox;
  bodyAsset?: Picklist;
  brandAsset?: Picklist;
  modelAsset?: Picklist;
  finishingAsset?: Picklist;
  fuelTypeAsset?: Picklist;
  emissionCO2Asset?: Picklist;
  powerHPAsset?: Picklist;
  qualificationType?: ComboBox;
  assets?: Picklist;
  typeCredits?: ComboBox;
  organization?: ComboBox;
  objects?: ComboBox;

  channel?: Picklist;

  //Task
  actionType?: Picklist;
  module?: ComboBox;

  decisionStatus?: ComboBox;
  decisionMotif?: ComboBox;
  decisionreserve?: ComboBox;
  decisionOpinion?: ComboBox;
  //Accept Offer
  acceptDecisionStatus?: ComboBox;
  reasons?: ComboBox;

  //communication
  messageSubjects?: ComboBox;

  //Scales
  financialProfile?: ComboBox;
  scaleStatus?: ComboBox;
  scaleCategory?: ComboBox;
  scaleClassification?: ComboBox;
  productTax?: ComboBox;
  scalefrequency?: ComboBox;
  scaleRateBasis?: ComboBox;
  scaleGracePeriodCalculationMethod?: ComboBox;
  serviceType?: ComboBox;
  productBasis?: ComboBox;
  accessoryCalculationMethod?: ComboBox;
  productTaxServices?: ComboBox;
  accessoryPaymentDatesOption?: ComboBox;
  //Asset
  assetTypeItems?: ComboBox;
  assetStatut?: ComboBox;
  assetCatalog?: ComboBox;

  //Garanty
  garantyStatus?: ComboBox;
  garantyType?: ComboBox;

  garantyBasis?: ComboBox;
  garantyrole?: ComboBox;
  underlyingType?: ComboBox;

  productAvailabilityContext?: ComboBox;

  //order
  orderStatus?: ComboBox;
  orderItemStatus?: ComboBox;
  orderTypeFinancing?: ComboBox;
  orderServiceType?: any;
  roleOrder?: ComboBox;
  delegationLevels?: ComboBox;
  subventionTypesOptions?: ComboBox;
  subventionOptions?: ComboBox;
  //User
  userSecurityGroup?: ComboBox;
  userTeam?: ComboBox;
  userNetwork?: ComboBox;
  userNetworkNode?: ComboBox;

  justifyTypes?: Picklist;
  supportingDocumentType?: Picklist;
  usersSchedule?: ComboBox;
}

export interface DemandState {
  isSaving?: boolean | false;
  isSummaryCharge?: boolean | false;
  offer?: Offer;
  assetState: AssetState;
  showSummary?: boolean | true;
  showRightSummary?: boolean | false;
  mockState?: boolean | false;
  showDecisionTab?: boolean | false;
  showUploadTab?: boolean | false;
  isEdit: boolean | false;
  proposals?: Proposal[];
  proposalScaleId?: string;
  deletedManager?: AssociatedParty;
  deletedContacts?: PartyContactMechanism[];
  partyTaxRate?: number;
  selectedAssetFrom?: any;
  selectedProductAsset?: any;
  tempSelectedOfferGoods?: any;
  quoteInfo?: string;
  partyInfo?: string;
  signatureMethod?: string;
  salesManager?: any;
  assignedSalesPerson?:any
}

export interface StatusHistoryVariables {
  offerReference?: string;
  offerStatus?: string;
  offerPhase?: string;
  offerCreationDate?: string;
  customerReference?: string;
  customerName?: string;
  customerType?: string;
  clientDecisionStatus?: string;
  taskDefinition?: string;
  comment?: string;
}

export interface AuthState {
  user: AuthUser;
  daaq: string;
  daaqLabel: string;
  daaqResourceUid: string;
  msgTemplate?: boolean;
  changePassworIn?: boolean;
  userAuthorities?: [];
  selectedAvatar?: any;
  groups?: [];
}

export interface AuthUser {
  _controls?: Controls;
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  phoneNumber?: string;
  applicationName?: string;
  rememberMe?: boolean;
  profileImage?: string;
  userId?: string;
  partyPersonId?: string;
  partyPersonSystemId?: string;
  userCode?: string;
  userEmail?: string;
  smsCode?: string;
  smsValidated?: boolean;
  associatedParty?: string;
  loggedIn?: boolean;
  forgotPassword?: boolean;
  authorities?: [];
  daaqList: any;
  defaultLanguage?: any;
  preferredLanguages?: any;
  selectedLanguage?: any;
  login?: string;
}

export interface ThirdState {
  third?: Third;
  balanceSheets?: BalanceSheet[];

  deletedRelationUid?: string;
}
export interface FinancialSituationState {
  balanceSheet: BalanceSheet;
}
export interface SimulationState {
  simulationChanged?: boolean;
  financingProductSelected?: any;
  isPriceEditable?: boolean;
  flagSelected?: boolean;
  rateFinancingTax: number;
  typeSimulation: string;
  productScale?: string;
  productLabel?: string;
  isFinancingTypeTTC?: boolean;
  price: number;
  simulationBody?: any;
  scales?: ScaleConfig[];
  scaleSelected?: ScaleConfig;
  simulationResponse?: SimulationResponse;
  services: ScaleService[];
  selectedServices: ScaleService[];
  sliderConfig?: ScaleSliderConfig[];
  calculationRequest: any;
  normalModeState: NormalModeState;
  expertModeState: ExpertModeState;
  pricingCalculationResponse?: PricingCalculationResponse;
  productTax?: ProductTax;
  toShowScaleBox: boolean | false;
  scaleTypeFiltered?: string;
  amountTTC?: number;
}

export interface NormalModeState {
  attributes: SimulationAttribute[];
}

export type SimulationAttribute = {
  attribute: string;
  percentValue?: number;
  value?: number;
  type: string;
};

export interface ExpertModeState {
  duration?: number;
  rate?: number;
  monthlyValue?: number;
  monthlyRate?: number;
  residualValue?: number;
  residualValueRate?: number;
  firstPaymentValue?: number;
  firstPaymentRate?: number;
  isCredit?: boolean | false;
}

export interface PricingCalculationRequest {
  searchStep: number;
  date: string;
  steps: CalculationStep[];
  services: [];
}

export interface CalculationStep {
  currency: string;
  paymentDatesOption: string;
  periodRateOption: string;
  paymentTermsOption: string;
  annualRate: number;
  numberOfPaymentTerms: number;
  outstandingBalanceBefore: CalculationAmount;
  outstandingBalanceAfter: CalculationAmount;
  payment: CalculationAmount;
  periodBetween2Installments: CalculationPeriod;
}

export interface CalculationAmount {
  amount: number;
  currency: string;
}

export interface CalculationPeriod {
  duration: number;
  unit: string;
}

export interface PricingCalculationResponse {
  isPricingResponse: true;
  hasFirstPayment?: boolean;
  resourceUid: any;
  objectType: string;
  systemUid: string;
  businessData: any;
  searchVariable: string;
  result: number;
  precision: number;
  terms: PricingCalculationResponseTerm[];
}

export interface PricingCalculationResponseTerm {
  resourceUid: any;
  objectType: string;
  systemUid: string;
  businessData: any;
  stepNo: string;
  periodStartDate: string;
  periodEndDate: string;
  dueDate: string;
  outstandingBalanceBefore: number;
  outstandingBalanceAfter: number;
  paidPrincipal: number;
  disbursement: number;
  interest: number;
  financialInstallment: number;
}

// Demand

export class DemandProgressStatus {
  icon: string;
  label: string;
  status: string[];
  color: string;
  doneIcon?: string;
  constructor(
    icon: string,
    color: string,
    label: string,
    statuses: string | string[], 
    doneIcon?: string
  ) {
    this.icon = icon;
    this.label = label;
    this.status = Array.isArray(statuses) ? statuses : [statuses];
    this.color = color;
    this.doneIcon = doneIcon;
  }
}

export type Demand = {
  offer?: Offer;
  third?: Third[];
};

export interface Offer extends UniformOdmName {
  validity: DateRange;
  reference: string;
  channel: string;
  salesChannel: any;
  associatedParties?: OfferAssociatedParty[];
  associatedNetworks: string | any;
  associatedNetworkNodes: string | any;
  assignedSalesPerson?: string;
  associatedUsers?: AssociatedUser[];
  assignedUser?: User;
  selfCareUser?: User;
  businessUnit?: UniformOdmName;
  geographicZone?: UniformOdmName;
  proposals: Proposal[];
  status: UniformOdmName;
  businessData?:any
  externalReference?: string;
}

export enum OfferStatus {
  INITIAL = "INITIAL",
  DECISION = "DECISION",
  CONTRACT = "CONTRACT",
  CANCELLED = "CANCELLED",
}
export enum ScaleBuisinessRuleSection {
  FINANCIAL = "FINANCIAL",
  SERVICE = "SERVICE",
  COMISSION = "COMISSION",
  AVAILABILITY = "AVAILABILITY",
  RESIDUALVALUE = "RESIDUALVALUE",
  FEES = "FEES",
  COMMISSION_APPLICATION_RULE = "COMMISSION_APPLICATION_RULE",
  SERVICE_APPLICATION_RULE = "SERVICE_APPLICATION_RULE",
}
export enum ScaleStatus {
  DRAFT = "DRAFT",
  SUSPENDED = "SUSPENDED",
  USE = "USE",
}

export enum ProposalStatus {
  INITIAL = "INITIAL",
  DECISION = "DECISION",
  CONTRACT = "CONTRACT",
  CANCELLED = "CANCELLED",
}

export enum AssociatedPartyRole {
  PROSPECT = "Prospect",
  DISTRIBUTOR = "Business partner / dealer",
  SUPPLIER = "Product supplier",
  OTHER = "Other role",
}

export interface OfferAssociatedParty extends UniformOdmName {
  id: number;
  tabName: string;
  showDeleteBtn: boolean;
  associatedParty?: AssociatedParty;
  third?: Third;
  daaq?: string;
  role_code?: string;
  uid?: string;
  signatureMethod?: any;
  signatureDate?: any;
}

// Third
export interface Third extends UniformOdmName {
  id?: UniformOdmName;
  type?: PicklistItem;
  person?: Person;
  organization?: Organization;
  accounts?: Account[];
  relations?: Relation[];
  correspondents?: PartyCorrespondent[];
  roles?: Role[];
  classifications?: PartyClassification[];
  addresses?: PartyAddress[];
  financialExposures?: financialExposures[];
  isDisabled?: boolean;
  whenCreated?: string;
  reference?: string;
  //balanceSheets?: BalanceSheet[];
}
export interface PartyClassification extends UniformOdmName {
  partyClass?: SelectComboBox;
  classType?: UniformOdmName;
  classificationDate?: string;
  validity?: DateRange;
  isDisabled?: boolean;
  daaq: string;
}

export interface Role extends UniformOdmName {
  role?: SelectComboBox;
  status?: PicklistItem;
  validity?: DateRange;
  nonSolicitation?: boolean;
  isDisabled?: boolean;
  preferences?: any;
  facilityPreferences?: any;
  geographicZone?: UniformOdmName;
}

export interface Network {
  id?: string;
  label?: string;
  user?: string;
}

export interface BalanceSheetItem extends UniformOdmName {
  label?: string;
  value?: MoneyAmount;
  nature?: any;
  isDisabled?: boolean;
  daaq?: string;
}
export type MoneyAmount = {
  amount: number;
  currency: string;
};
export enum ThirdType {
  PERSON = "party-FrenchPerson",
  ORGANIZATION = "party-FrenchOrganization",
  TYPE_PERSON = "frenchperson",
  TYPE_ORGANIZATION = "frenchorganization",
  MANAGER = "party-FrenchManager",
  SUPPLIER = "party-supplier",
}

export interface PartyRelation extends UniformOdmName, DAAQ {
  relationType: UniformOdmName;
  partyAsc: UniformOdmName;
  partyDesc: UniformOdmName;
  validity: DateRange;
  sharingRate: Rating;
}

export interface Manager extends Person {
  validity: DateRange;
  relationType: SelectComboBox;
  show?: boolean;
}
export interface Person extends UniformOdmName {
  usageName?: string;
  title?: SelectComboBox;
  firstName: string;
  middleName?: string;
  familyName: string;
  birthDate: string;
  age?: number;
  birthplace?: PostalCodeAddress;
  currentMaritalStatus?: SelectComboBox;
  currentMatrimonialRegime?: SelectComboBox;
  nbOfChildren?: number;
  gender?: SelectComboBox;
  driverLicenceDate?: Date;
  currentStatus?: UniformOdmName;
  ids?: IdentityDocument[];
  taxID?: string;
  businessData?: object;
  legalEntity?: UniformOdmName;
  maritalStatuses?: PersonMaritalStatus[];
  citizenships?: Citizenship[];
  contacts?: PartyContactMechanism[];
  addresses?: PartyAddress[];
  accounts?: Account[];
  correspondents?: PartyCorrespondent[];
  roles?: Role[];
  classifications?: PartyClassification[];
  nbOfDependent?: number;
  jobs?: Jobs[];
  homemakers?: string;
  retirementPassage?: string;
  birthChildren?: BirthChildren[];
  removedSituation?: boolean;
  revenues?: RevenuesAnalysis[];
  nationality?: SelectComboBox;
  daaq?: string;
  managerType?: string;
  relations?: Relation[];
  taxGeographicZone?: UniformOdmName | string;
  reference?: string;
  type?: any;
  status?: any;
  financialExposures?: financialExposures[];
  externalReference?: string
}

export class PersonClass implements Person {
  resourceUid?: string = "";
  objectType?: string = "";
  usageName?: string = "";
  title?: SelectComboBox = undefined;
  firstName: string;
  middleName?: string = "";
  familyName: string;
  birthDate: string;
  age?: number = 0;
  birthplace?: PostalCodeAddress = undefined;
  currentMaritalStatus?: SelectComboBox = undefined;
  currentMatrimonialRegime?: SelectComboBox = undefined;
  nbOfChildren?: number = 0;
  gender?: object = {};
  driverLicenceDate?: Date = new Date();
  currentStatus?: UniformOdmName = {};
  ids?: IdentityDocument[] = [];
  taxID?: string = "";
  businessData?: object = {};
  legalEntity?: UniformOdmName = {};
  maritalStatuses?: PersonMaritalStatus[] = [];
  citizenships?: Citizenship[] = [];
  contacts?: PartyContactMechanism[] = [];
  addresses?: PartyAddress[] = [];
  accounts?: Account[] = [];
  correspondents?: PartyCorrespondent[] = [];
  roles?: Role[] = [];
  classifications?: PartyClassification[] = [];
  nbOfDependent?: number = 0;
  jobs?: Jobs[] = [];
  homemakers?: string = "";
  retirementPassage?: string = "";
  birthChildren?: BirthChildren[] = [];
  removedSituation?: boolean = false;
  revenues?: RevenuesAnalysis[] = [];
  financialExposures?: financialExposures[] = [];
  nationality?: SelectComboBox = undefined;

  constructor(firstName: string, familyName: string, birthDate: string) {
    this.firstName = firstName;
    this.familyName = familyName;
    this.birthDate = birthDate;
  }
}

export interface BirthChildren {
  dateOfBirthChildren?: string;
  childrenAge?: number;
}

export interface RevenuesAnalysis extends UniformOdmName {
  label?: string;
  analysisDate?: string;
  status?: SelectComboBox;
  yearlyExpensesTotal?: CalculationAmount;
  yearlyIncomeTotal?: CalculationAmount;
  detail?: ExpenseIncome[];
  isDisabled?: boolean;
}

export interface ExpenseIncome extends UniformOdmName {
  type?: string;
  nature?: SelectComboBox;
  periodicity?: SelectComboBox;
  supportingDocument?: UniformOdmName;
  value?: number;
  label?: string;
  validity?: DateRange;
  isDisabled?: boolean;
}

export interface Jobs extends UniformOdmName {
  positionType?: SelectComboBox;
  occupationType?: SelectComboBox;
  activity?: SelectComboBox;
  nbYearExperienceActivity?: number;
  nbYearExperiencePosition?: number;
  employer?: Organization;
  validity?: DateRange;
  employerName?: string;
  principal?: boolean | false;
  nbHour?: number;
  employerCreationDate?: string;
  tenureDate?: string;
  isDisabled?: boolean;
  contractType?: SelectComboBox;
  adress?: string;
  number?: string;
  jobLocation?: any;
}

export interface Organization extends UniformOdmName {
  siren: string;
  codeApe: string;
  codeNACE?: SelectComboBox;
  numTVAIntraCom: string;
  name: string;
  commercialName?: string;
  commercialRegister?: string;
  isCreated?: boolean;
  creationDate?: Date;
  acronym?: string;
  legalCategory?: SelectComboBox;
  activitySector?: SelectComboBox;
  currentStatus?: UniformOdmName;
  taxID?: string;
  objectType?: string;
  systemUid?: string;
  resourceUid?: string;
  businessData?: string;
  activity?: DateRange;
  contacts?: PartyContactMechanism[];
  facilities?: Facility[];
  addresses?: PartyAddress[];
  accounts?: Account[];
  correspondents?: PartyCorrespondent[];
  roles?: Role[];
  classifications?: PartyClassification[];
  balanceSheets?: BalanceSheet[];
  financialExposures?: financialExposures[];
  relations?: Relation[];
  manager?: Manager;
  daaq?: string;
  taxGeographicZone?: UniformOdmName | string;
  reference?: string;
  type?: any;
  status?: any;
}

export class OrganizationClass implements Organization {
  siren: string;
  codeApe: string;
  codeNACE?: SelectComboBox = {};
  numTVAIntraCom: string;
  name: string;
  commercialRegister?: string = "";
  isCreated?: boolean;
  creationDate?: Date;
  commercialName?: string = "";
  acronym?: string = "";
  legalCategory?: SelectComboBox = {};
  activitySector?: SelectComboBox = {};
  currentStatus?: UniformOdmName = {};
  taxID?: string = "";
  objectType?: string = "";
  systemUid?: string = "";
  resourceUid?: string = "";
  businessData?: string = "";
  activity?: DateRange = { from: "", until: "" };
  contacts?: PartyContactMechanism[] = [];
  facilities?: Facility[] = [];
  addresses?: PartyAddress[] = [];
  accounts?: Account[] = [];
  correspondents?: PartyCorrespondent[] = [];
  roles?: Role[] = [];
  classifications?: PartyClassification[] = [];
  balanceSheets?: BalanceSheet[] = [];
  financialExposures?: financialExposures[] = [];
  relations?: Relation[] = [];

  constructor(
    siren: string,
    codeApe: string,
    numTVAIntraCom: string,
    name: string
  ) {
    this.siren = siren;
    this.codeApe = codeApe;
    this.numTVAIntraCom = numTVAIntraCom;
    this.name = name;
  }
}

export interface PartyAddress extends UniformOdmName {
  daaq: string;
  address: Address;
  livingSituation?: any;
}

export interface BalanceSheet extends UniformOdmName {
  label?: string;
  country?: any;
  status?: any;
  period: DateRange;
  supportingDocument?: any;
  detail?: BalanceSheetItem[];
  isDisabled?: boolean;
  daaq?: string;
}

export interface financialExposures extends UniformOdmName {
  grossOutstanding?: string;
  outstandingType?: any;
  netOutstanding?: number;
  residualValue?: number;
  flagFinancialPool?: boolean;
  flagRiskPool?: boolean;
  shareFinancialPool?: number;
  shareRiskPool?: number;
  effectiveDate?: Date;
  contractReference?: string;
  contractValidity?: Validity;
  financialInstitution?: any;
  businessData?: any;
}

export interface Facility extends UniformOdmName {
  facilityType?: SelectComboBox;
  legalId?: string;
  activity?: DateRange;
  name?: string;
  address?: any;
  siret?: string;
  isDisabled?: boolean;
  daaq?: string;
}

export default interface FrenchFacility extends Facility {
  siret?: string;
}

export interface PersonMaritalStatus extends UniformOdmName {
  validity: DateRange;
  maritalStatus: SelectComboBox;
  matrimonialRegime: SelectComboBox;
}

export type Contact = {
  lastName?: string;
  occupation?: string;
  relationship?: string;
  phone?: string;
  mail?: string;
  firstName?: string;
  signature?: boolean | false;
};

export interface Address extends UniformOdmName {
  type?: AddressType;
  validity?: DateRange;
  postalCode?: PostalCode;
  street?: string;
  streetNum?: number;
  mailbox?: number;
  line1?: string;
  line2?: string;
  line3?: string;
  territory?: string;
  isDisabled?: boolean;
  daaq?: string;
}

export interface PostalCodeAddress extends UniformOdmName {
  type?: PicklistItem;
  postalCode?: string;
  country?: Country;
  //TODO: review this use of value
  value?: string;
  department?: string;
}

export interface PostalCode extends UniformOdmName {
  type?: ComboBoxItem;
  postalCode?: string;
  country?: Country;
  inseeCode?: string;
  region?: string;
  department?: string;
  city?: string;
}

export interface Country extends UniformOdmName {
  name: string;
}

export interface AddressType extends UniformOdmName {
  type?: any;
  businessData?: never;
}

export type Citizenship = {
  validity: DateRange;
  country: any;
  identityDocument: IdentityDocument;
  objectType: string;
  systemUid: string;
  resourceUid: string;
  businessData: any;
};

export type Account = {
  resourceUid?: string;
  objectType?: string;
  systemUid?: string;
  businessData?: any;
  bankAccount: BankAccount;
  id?: UniformOdmName;
  isDisabled?: boolean;
  daaq?: string;
};

export type BankAccount = {
  resourceUid?: string;
  objectType?: string;
  systemUid?: string;
  businessData?: any;
  bankAgency?: any;
  status?: any;
  type?: any;
  validity?: DateRange;
  account?: string;
  key?: string;
  iban?: string;
  bic?: string;
  isPreferred?: boolean;
};

export interface PartyCorrespondent extends UniformOdmName {
  _controls?: Controls;
  firstName: string;
  middleName: string;
  familyName: string;
  status: UniformOdmName;
  signingAuthority: boolean | false;
  contactCorrespondent: PartyContactCorrespondent[];
  qualificationType: QualificationType;
  isDisabled?: boolean;
}

export interface PartyContactCorrespondent extends UniformOdmName {
  contactMechanism: IContactMechanism;
  daaq?: string;
}

export interface PartyContactMechanism extends UniformOdmName {
  daaq?: string;
  contactMechanism: IContactMechanism;
  consent?: any[]
  facility?: Facility
}

export interface QualificationType extends UniformOdmName {
  type?: ComboBoxItem;
  label?: "";
}

export enum PartyCorrespondentStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  TRANSFORM = "TRANSFORM",
}

export enum ContactType {
  EMAIL_CONTACT = "EMAIL_CONTACT",
  MESSENGER_CONTACT = "MESSENGER_CONTACT",
  PHONE_CONTACT = "PHONE_CONTACT",
  POSTAL_MAIL_CONTACT = "POSTAL_MAIL_CONTACT",
}

export enum GuaranteeType {
  MORTGAGE = "mortgage",
  BANK_GUARANTEE = "bankguarantee",
  PROPERTY_PLEDGING = "propertypledging",
  BUY_BACK = "buyback",
  PERSONAL_GUARANTEE = "personalguarantee",
}

export type ContactRequest = {
  type?: PicklistItem;
  email?: Email;
  messenger?: Messenger;
  phone?: Phone;
  postalMail?: PostalMail;
  isDisabled: boolean;
};

export interface IContactMechanism extends UniformOdmName {
  _controls?: Controls;
  type?: PicklistItem;
  emailAddress?: string;
  client?: UniformOdmName;
  identifier?: string;
  phoneNumber?: string;
  country?: UniformOdmName;
  acceptSMS?: boolean;
  address?: UniformOdmName;
  validity?: DateRange;
  nonSolicitation?: boolean;
  consent?: ContactConsent[];
  facility?: UniformOdmName;
  isDisabled: boolean;
  blocked?: boolean;
  businessData?: any;
  daaq?: string;
}

export interface IdentityDocument extends UniformOdmName {
  reference: string;
  emissionDate: string;
  authority: string;
  validity: DateRange;
  documentType: IdentityDocumentType;
  supportingDocument: UniformOdmName;
  businessData?: any;
  isDisabled?: boolean;
}

export interface IdentityDocumentType extends UniformOdmName {
  type?: PicklistItem;
  businessData?: any;
}

export type Email = {
  emailAddress: string;
  systemUid?: string;
  resourceUid?: string;
  validity?: DateRange;
  nonSolicitation?: boolean;
  consent?: ContactConsent[];
  facility?: UniformOdmName;
  daaq?: string;
};

export type Messenger = {
  systemUid?: string;
  resourceUid?: string;
  client?: any;
  identifier?: string;
  validity?: DateRange;
  nonSolicitation?: boolean;
  consent?: ContactConsent[];
  facility?: UniformOdmName;
  daaq?: string;
};

export type Phone = {
  phoneNumber: string;
  country?: UniformOdmName;
  acceptSMS: boolean;
  systemUid?: string;
  resourceUid?: string;
  validity?: DateRange;
  nonSolicitation?: boolean;
  consent?: ContactConsent[];
  facility?: UniformOdmName;
  daaq?: string;
};

export type PostalMail = {
  address: UniformOdmName;
  systemUid?: string;
  resourceUid?: string;
  validity?: DateRange;
  nonSolicitation?: boolean;
  consent?: ContactConsent[];
  facility?: UniformOdmName;
  daaq?: string;
};

export type ContactConsent = {
  validity?: DateRange;
  type?: any;
  objectType?: string;
  systemUid?: string;
  resourceUid?: string;
  businessData?: any;
  daaq?: string;
};

export interface Relation extends UniformOdmName {
  relation?: SelectComboBox;
  relationType?: SelectComboBox;
  partyAsc?: any;
  partyDesc?: any;
  validity?: DateRange;
  party?: UniformOdmName;
  name?: string;
  isDisabled?: boolean;
  sharingRate?: number;
}

export interface RelationParty extends UniformOdmName {
  label?: string;
  id?: string;
}

export enum RelationType {
  ASCENDING = "ascending",
  DESCENDING = "descending",
}

export type DateRange = {
  from: string;
  until: string;
};

export enum Roles {
  CUSTOMER = "CUSTOMER",
  DIRG = "DIRG",
  DISTRIBUTOR = "DISTRIBUTOR",
  GARANT = "GARANT",
}

export const customRoles = ["DISTRIBUTOR","SUPPLIER"]

// Calculation

export type CalculationRequest = {
  searchVariable: SearchType;
  financialProductUid: UniformOdmName;
  price: number;
  duration?: number;
  residualValue?: number;
  firstPayment?: number;
  monthlyPayment?: number;
  millage?: number;
};

export enum SearchType {
  PAYMENT = "Payment",
  FIRST_PAYMENT = "FirstPayment",
  ANNUAL_RATE = "AnnualRate",
  OUTSTANDING_BALANCE_BEFORE = "OutstandingBalanceBefore",
  OUTSTANDING_BALANCE_AFTER = "OutstandingBalanceAfter",
}

// Sliders

export interface SliderGroupConfig {
  sliders: SliderConfig[];
}

export type SliderConfig = {
  id: string;
  attribute: string;
  calculationType?: CalculationType;
  slideType?: SlideType;
  label: string;
  suffix: string;
  interval: number;
  value: number;
  min: number;
  max: number;
  percentValue?: number;
  percentMin?: number;
  percentMax?: number;
  hideCalculationButton?: boolean;
  optional?: boolean;
  model: number;
  minSpread?: number;
  maxSpread?: number;
  isCustomSuffix?: boolean;

  minSuffix?: string;
  maxSuffix?: string;
};

export enum CalculationType {
  PAYMENT = "Payment",
  ANNUAL_RATE = "AnnualRate",
  OUTSTANDING_BALANCE_AFTER = "OutstandingBalanceAfter",
  OUTSTANDING_BALANCE_BEFORE = "OutstandingBalanceBefore",
  FIRST_PAYMENT = "FirstPayment",
}

export enum SlideType {
  DEFAULT = "DEFAULT",
  PERCENT = "PERCENT",
  RATE = "RATE",
}

//services

export type ScaleService = {
  required?: boolean;
  selected?: boolean;
  resourceUid: string;
  name?: string;
  status?: string;
  currency?: string;
  nature?: string;
  paymentDatesOption?: string;
  flagIncludingAPRC?: boolean;
  qualification?: string;
  amount?: MoneyAmount;
  amountMin?: MoneyAmount;
  amountMax?: MoneyAmount;
  calculationMethodOption?: string;
  annualRate?: number;
  annualRateMin?: number;
  annualRateMax?: number;
  basis?: string;
};

export type ServiceConfig = {
  id: number;
  config: Config;
  type: OptionConfig;
  name: OptionConfig;
  baseCalcule: OptionConfig;
  min: number;
  valuePercent: number;
  max: number;
  qt: number;
  duration: number;
  montantHt: number;
  montantTtc: number;
  paymentFrequence?: OptionConfig;
  multiple?: number;
  term?: OptionConfig;
};
export type Config = {
  required: boolean;
  selected: boolean;
};

export type OptionConfig = {
  id: string;
  label: string;
};

// Scales

export type ScaleResponse = {
  scales: ScaleConfig[];
  accessories: any;
};

export type ScaleConfig = {
  visible?: boolean | true;
  resourceUid?: string;
  loading?: boolean | true;
  selected?: boolean;
  name: string;
  tittle: string;
  subTittle: string;
  presentation: ScalePresentationConfig[];
  sliderConfig: ScaleSliderConfig[];
  financialProductPack?: FinancialProductPack;
  financialProductItem?: any;
  classification?: any;
  activity?: any;
  numberOfPaymentTerms?: number;
  annualRate?: number;
  items?: any;
};
export type FinancialProductPack = {
  resourceUid: string;
  name?: string;
  validity?: Validity;
  status: UniformOdmName;
  qualifier: string;
  tax: string;
  features: ScaleConfigFeatures[];
  presentation: ScalePresentationConfig[];
  sliderConfig: ScaleSliderConfig[];
  financialProfile: FinancialProfile;
};

export interface FinancialProfile extends UniformOdmName {
  validity?: Validity;
  qualifier: string;
  paymentTermsOption: UniformOdmName;
  paymentDatesOption: UniformOdmName;
  periodRateOption: UniformOdmName;
  taxOnInterest: UniformOdmName;
  investmentBasis: string;
}

export type ScaleConfigFeatures = {
  resourceUid: string;
  feature: UniformOdmName;
  includeInPackagePrice: boolean;
  packageFeatureType: string;
  required: boolean;
};

export type ScalePresentationConfig = {
  label: string;
  value: string;
};

export type ScaleSliderConfig = {
  attribute: string;
  value: number;
  typeSelected?: string;
};

export type SearchPerson = {
  id?: string;
  discriminator?: string;
  reference?: string;
  firstName?: string;
  middleName?: string;
  familyName?: string;
  birthDate?: string;
  currentMaritalStatusCode?: string;
  titleCode?: string;
  territory?: string;
  street?: string;
  streetNum?: string;
  mailbox?: string;
  postalCode?: any;
  phoneNumber?: string;
  countryCode?: string;
  mail?: string;
  role?: string;
};

export interface SearchBankAgency {
  name?: string;
  code?: string;
  bankId?: string;
  codeSwift?: string;
}

export interface BankAgency {
  id: string;
  name: string;
  code: string;
  country_code: string;
  end_date: string;
  address_id: string;
  bank_id: string;
  code_swift: string;
}

export type SearchOrganization = {
  id?: string;
  discriminator?: string;
  reference?: string;
  name?: string;
  commercialName?: string;
  siren?: string;
  acronym?: string;
  activitySectorCode?: string;
  legalCategoryCode?: string;
  territory?: string;
  street?: string;
  streetNum?: string;
  mailbox?: string;
  postalCode?: any;
  countryCode?: string;
  asset?: string;
  role?: string;
};

export type SearchSupplier = {
  id?: string;
  discriminator?: string;
  reference?: string;
  name?: string;
  commercialName?: string;
  siren?: string;
  acronym?: string;
  activitySectorCode?: string;
  legalCategoryCode?: string;
  territory?: string;
  street?: string;
  streetNum?: string;
  mailbox?: string;
  postalCode?: string;
  countryCode?: string;
  productCode?: string;
};

export type FilterParty = {
  id?: string;
  reference?: string;
  siren?: string;
  family_name?: string;
  birthday?: string;
  firstName?: string;
  commercial_name?: string;
  city?: string;
  siret?: string;
  discriminator?: string;
  daaq?: string;
  relationTypeCode?: string;
  phoneNumber?: string;
  role?: ComboBoxItem;
  current_status_code?: PicklistItem;
  postalCode?: string;
};

export type FilterCatalog = {
  id?: string;
  reference?: string;
  name?: string;
  validity_from?: string;
  validity_until?: string;
};

export interface ThirdSearch {
  role_code?: PicklistItem;
  typeClient?: PicklistItem;
  current_status_code?: PicklistItem;
  postalCode?: PicklistItem;
  phone?: string;
  relationTypeCode?: string;
}

export interface OfferSearch {
  from?: string;
  until?: string;
  demandStatus?: PicklistItem;
  brand?: PicklistItem;
  range?: PicklistItem;
  reference?: string;
  firstName?: string;
  familyName?: string;
  commercialName?: string;
  clientReference?: string;
  role?: string[];
  listRoles?: PicklistItem;
  siren?: string;
  typeClient?: PicklistItem;
  channel?: PicklistItem;
  amount?: string;
  network?: PicklistItem;
  networkNode?: PicklistItem;
  immatriculation?: string;
  _decisionAnalysisProcessId?: PicklistItem;
  externalReference?: string;
}

export class Expression {
  table: string;
  field: string;
  operator: string;
  value: any;
  parameter: string;

  constructor(
    table: string,
    field: string,
    operator: string,
    value: any,
    parameter: string
  ) {
    this.table = table;
    this.field = field;
    this.operator = operator;
    this.value = value;
    this.parameter = parameter;
  }
}

// export type SearchPersonAndOrganization = {
//   id?: string
//   discriminator?: string
//   firstName?: string
//   middleName?: string
//   familyName?: string
//   birthDate?: string
//   currentMaritalStatusCode?: string
//   titleCode?: string
//   province?: string
//   street?: string
//   city?: string
//   zipCode?: string
//   phoneNumber?: string
//   countryCode?: string
//   mail?: string

//   name?: string
//   commercialName?: string
//   siren?: string
//   acronym?: string
//   activitySectorCode?: string
//   legalCategoryCode?: string
// }

export type SearchPersonState = {
  personSelected?: SearchPerson;
  people?: SearchPerson[];
};

// expert mode

export type Step = {
  id: number;
  step?: number;
  selected: boolean;
  duration: number;
  terme: any;
  calculationMethod: any;
  periodicity?: number;
  periodicityCode?: string;
  rate: number;
  paymentValue?: number;
  paymentRate?: number;
  residualValue?: number;
  residualValueRate?: number;
};

//Asset
export interface AssetState {
  type?: string;
  mode: string;
  proposalAssets: ProposalAssetCar[] | ProposalAssetTruck[];
  credits: Credit[];
  projects: Project[];
  overdraw: Overdraw;
  cash: Cash;
  toShowAssetBox: boolean | false;

  isFirstTab: boolean;
}

export interface Credit extends ProposalAsset {
  typeCredits: SelectComboBox;
  supplier: SelectComboBox;
  object: SelectComboBox;
  monthlyPayment: number;
  monthlyPaymentFormatted: string;
  amountToRepay: number;
  amountToRepayFormatted: string;
  originalAmount: number;
  originalAmountFormatted: string;
  startDate: Date;
  endDate: Date;
}

export interface Project extends ProposalAsset {
  typeProjects: SelectComboBox;
  supplier: SelectComboBox;
  amount: number;
  amountFormatted: string;
}

export interface Overdraw extends ProposalAsset {
  overdraft?: number;
}

export interface Cash extends ProposalAsset {
  treasury?: number;
}

export interface ProposalAssetCar extends ProposalVehicle {
  objectType: "odm.offer.proposalasset.proposalgood.proposalvehicle.proposalcar";
  image?:Image;
  nbOfDoor?: number;
  nbOfSeat?: number;
}

export interface ProposalAssetTruck extends ProposalVehicle {
  objectType: "odm.offer.proposalasset.proposalgood.proposalvehicle.proposaltruck";
  image?:Image;
}

export interface ProposalVehicle extends ProposalAsset {
  flagNew?: boolean;
  nbOfCylinder?: number;
  nbOfGear?: number;
  taxHorsePower?: number;
  horsePower: number;
  co2Emissions: number;
  mineType?: number;
  fuelConsumptionUrban?: number;
  fuelConsumptionExtraUrban?: number;
  fuelConsumptionCombined?: number;
  brand: string;
  range: string;
  version: string;
  carBodyStyle: string;
  gearBox?: string;
  engineType: string;
  matriculation?: string;
  matriculationDate?: Date;
  chassisReference?: Date;
  guarantee?: any;
  assetDelivery?: any;
  proposalAssetCategories?: any;
}

export interface Asset extends UniformOdmName {
  id: number;
  code?: any;
  showDeleteBtn: boolean;
  category: any;
  body: any;
  brand: any;
  model: any;
  finishing: any;
  fuelType: any;
  emissionCO2: any;
  powerHP: any;
  supplier: AssociatedParty;
  quantity: number;
  product: CarProduct;
  options: OptionsAsset[];
  accessories: AccessoriesAsset[];
  packs: PacksAsset[];
  miscellaneousCosts: MiscellaneousCostsAsset[];
  totalUnitaryPrice: number;
  totalPriceWithDiscount: number;
  totalPriceWithTax: number;
  VN: boolean;
  VO: boolean;
  description: any;
}

export interface Price {
  unitaryPrice: number;
  discount: number;
  priceWithDiscount: number;
  tax: number;
  priceWithTax: number;
  quantity?: number;
}

export interface AccessoriesAsset extends Price {
  id: number;
  accessory?: string;
  required?: boolean;
  includeInPackagePrice?: boolean;
  selected?: boolean;
}

export interface MiscellaneousCostsAsset extends Price {
  id: number;
  selected?: boolean;
  miscallaneousCost?: string;
  required?: boolean;
}

export interface OptionsAsset extends Price {
  id: number;
  option?: string;
  required?: boolean;
  includeInPackagePrice?: boolean;
  selected?: boolean;
}

export interface ContentPackAsset extends Price {
  id: number;
  label?: string;
  type?: string;
}

export interface PacksAsset extends Price {
  id: number;
  pack?: string;
  required?: boolean;
  includeInPackagePrice?: boolean;
  selected?: boolean;
  content?: ContentPackAsset[];
}

//TODO - Review after stubs created

export interface CarProduct {
  nbOfDoor?: number;
  nbOfSeat?: number;
  nbOfCylinder?: number;
  nbOfGear?: number;
  taxHorsePower?: number;
  powerPhp?: number;
  co2Emissions?: number;
  mineType?: number;
  fuelConsumptionUrban?: number;
  fuelConsumptionExtraUrban?: number;
  fuelConsumptionCombined?: number;
  millage?: Millage;
  residualValue?: ResidualValue;
  validity?: Validity;
  status?: Status;
  classifications?: Classifications[];
  name?: string;
  qualifier?: Qualifier;
  tax: number;
  feature?: Feature[];
  objectType?: string;
  systemUid?: string;
  resourceUid?: string;
  businessData?: any;

  //add manual
  unitaryPrice: number;
  discount: number;
  priceWithDiscount: number;
  priceWithTax: number;
}
export interface MillageRule {}
export interface Millage {
  ruleEngineId: string;
  rule: MillageRule;
}
export interface ResidualValueRule {}
export interface ResidualValue {
  ruleEngineId: string;
  rule: ResidualValueRule;
}
export interface Validity {
  from: string;
  until: string;
}
export interface Status {}
export interface ClassificationsValidity {
  from: string;
  until: string;
}

export interface ClassificationsCategoryClass {
  objectType?: string;
  systemUid?: string;
  resourceUid?: string;
}
export interface ClassificationsCategory {
  objectType?: string;
  systemUid?: string;
  resourceUid?: string;
}
export interface ClassificationsBusinessData {}
export interface Classifications {
  validity?: ClassificationsValidity;
  categoryClass: ClassificationsCategoryClass;
  priority: number;
  category: ClassificationsCategory;
  objectType?: string;
  systemUid?: string;
  resourceUid?: string;
  businessData: ClassificationsBusinessData;
  daaq?: string;
  isClone?: boolean;
}
export interface Qualifier {
  rawValue: string;
}

export interface FeatureBusinessData {}
export interface Feature {
  defaultValue: boolean;
  label: string;
  internalCode: string;
  name: string;
  standardValue: number;
  minValue: number;
  maxValue: number;
  currency: string;
  basis: string;
  objectType: string;
  systemUid: string;
  resourceUid: string;
  businessData: FeatureBusinessData;
  validity?: Validity;
  discount?: number;
}

export interface Controls {
  attToRemove?: string[];
  dateFormat?: string | "yyyy-mm-dd";
}

// Offer

export interface Proposal extends UniformOdmName {
  status: UniformOdmName;
  flagSelected: boolean;
  proposalDecisions?: Decision[];
  proposalItems: SimpleQuote[] | AdvancedQuote[];
  proposalInstructions?: any;
  daaq?: string;
}

// Quotes
export interface SimpleQuote extends ContractOrigination {
  objectType: "odm.offer.proposalitem.contractorigination.simplequote";
  numberOfPaymentTerm?: number;
  periodBetween2Installments?: ElapseTime;
  rateType: string;
  rateBasis?: UniformOdmName;
  spread?: number;
  nominalRate?: number;
  rateBaseValue?: number;
  ratePeriodicity?: ElapseTime;
  periodRateOption?: UniformOdmName;
  paymentDatesOption?: UniformOdmName;
  paymentTermsOption?: UniformOdmName;
  gracePeriodDuration?: number;
  gracePeriodPeriodicity?: ElapseTime;
  gracePeriodRate?: number;
  gracePeriodCalculationMethodOption?: string;
  firstPaymentWoTax?: number;
  firstPaymentWTax?: number;
  firstPaymentPercentage?: number;
  lastPayment?: number;
  financedValue?: number | null;
  residualValueAmount?: number;
  residualValueAmountWoTax?: number;
  residualValueAmountWTax?: number;
  residualValuePercentage?: number;
  financialPaymentWTax?: number;
  financialPaymentWoTax?: number;
  originationPaymentSchedule?: UniformOdmName;
  downPayment?: number;
  downPaymentPercentage?: number;
  mileage?: number;
  price?: number;
  numberGracePeriodTerms?: number;
}

export interface AdvancedQuote extends ContractOrigination {
  objectType: "odm.offer.proposalitem.contractorigination.advancedquote";
  contractOriginationSteps: ContractOriginationStep[];
}

// Contract

export interface ContractOrigination extends UniformOdmName {
  startDate: string;
  endDate: string;
  taxCode?: UniformOdmName;
  taxValue?: number;
  downPayment?: number;
  downPaymentPercentage?: number;
  numberOfPaymentTerm?: number;
  financingProductPack?: UniformOdmName;
  financingOffer?: UniformOdmName;
  financingProduct?: string;
  parentProposal?: UniformOdmName;
  proposalAssets: ProposalAssetCar[] | ProposalAssetTruck[];
  proposalAccessories: ProposalAccessories[];
  proposalPaymentSchedules?: OriginationPaymentSchedule[];
  daaq?: string;
  aprc?: number;
  air?: number;
  activityType?: string;
  sumOfTotalPayments?: MoneyAmount;
  proposalGuarantees?: Array<ProposalGuaranteeClass>;
}

export interface ProductTax extends UniformOdmName {
  code: string;
  matrix?: string;
  evaluationMethod: PicklistItem;
  validity: DateRange;
  fixedValue: number;
}

// Steps

export interface OriginationPaymentSchedule extends UniformOdmName {
  startDate: string;
  endDate: string;
  paymentScheduleTerm: PaymentScheduleTerm[];
}

export interface PaymentScheduleTerm extends UniformOdmName {
  dueDate: string;
  periodStartDate: string;
  periodEndDate: string;
  num?: number;
  interest?: number;
  financialInstallmentWoTax?: number;
  taxAmount?: number;
  financialInstallmentWTax?: number;
  outstandingBalanceBefore?: number;
  outstandingBalanceAfter?: number;
  paidPrincipal?: number;
}

export interface ElapseTime {
  duration: number;
  unit: string;
}

export interface ContractOriginationStep extends UniformOdmName {
  numberOfPaymentTerm?: number;
  periodBetween2Installments?: ElapseTime;
  rateType: string;
  rateBasis?: UniformOdmName;
  spread?: number;
  nominalRate?: number;
  ratePeriodicity?: ElapseTime;
  periodRateOption?: UniformOdmName;
  paymentDatesOption?: UniformOdmName;
  paymentTermsOption?: UniformOdmName;
  outstandingBalanceBefore?: number;
  outstandingBalanceAfter?: number;
  financialPaymentWTax?: number;
  financialPaymentWoTax?: number;
  precision?: number;
  originationPaymentSchedule?: UniformOdmName;
}

// Proposal Assets

export interface ProposalAsset extends UniformOdmName {
  config?: any;
  taxCode?: UniformOdmName;
  party?: AssociatedParty;
  usage?: PicklistItem;
  proposalAssetItems?: ProposalAssetItem[];
  assetProduct?: UniformOdmName;
  packAssetProduct?: UniformOdmName;
  taxValue?: number;
  amountWoTax?: MoneyAmount;
  discountPercentage?: number;
  discountAmount?: MoneyAmount;
  taxAmount?: MoneyAmount;
  amountWTax?: MoneyAmount;
  quantity?: number;
  totalAmountWoTax?: MoneyAmount;
  totalTaxAmount?: MoneyAmount;
  totalAmountWTax: MoneyAmount;
  displayAssetType?: string;
  takenBack?: boolean;
  _business_data?: any;
  description?: string;
  proposalAssetCategories?: any;
  boatName?: string;
  securityEquipment?: string;
  designCategory?: string;
  associatedPort?: string;
  associatedFlag?: string;
  nbEngine?: any;
  purchaseAmountWoTax?: MoneyAmount;
  purchaseAmountWTax?: MoneyAmount;
  boatCoverage?: string;
  orderAssetComponents?: any;
}

export interface AssociatedParty extends UniformOdmName {
  party?: UniformOdmName | any;
  role?: ComboBoxItem | UniformOdmName;
  role_code?: string;
  name?: string;
  third?: Third;
}

export interface AssociatedNetwork extends UniformOdmName {
  id?: string;
  label?: string;
}
export interface AssociatedNetworkNode extends UniformOdmName {
  id?: string;
  label?: string;
}

export interface assignedSalesPerson extends UniformOdmName {
  id?: string;
  label?: string;
}

export interface ProposalAssetItem extends UniformOdmName {
  objectType: "odm.offer.proposalassetitem";
  config?: any;
  taxCode?: UniformOdmName;
  type?: ProposalAssetItemType | UniformOdmName;
  label?: string;
  taxValue?: number;
  amountWTax?: MoneyAmount;
  discountPercentage?: number;
  discountAmount?: MoneyAmount;
  taxAmount?: MoneyAmount;
  amountWoTax?: MoneyAmount;
  quantity?: number;
  totalAmountWoTax?: MoneyAmount;
  totalTaxAmount?: MoneyAmount;
  totalAmountWTax?: MoneyAmount;
  purchaseAmountWoTax?: MoneyAmount;
  purchaseAmountWTax?: MoneyAmount;
}

export enum ProposalAssetItemType {
  option = "OPTION_CAR",
  accessory = "ACCESSORY_CAR",
  other = "OTHER_CAR",
}

export enum OrderProposalAssetItemType {
  option = "OPTION",
  accessory = "ACCESSORY",
  other = "OTHER",
}

// Simulation

export interface SimulationRequest extends UniformOdmName {
  financialProductUid: UniformOdmName;
  productUid: UniformOdmName;
  duration?: number;
  millage?: number;
  price?: number;
  residualValue?: number;
  firstPayment?: number;
  monthlyPayment?: number;
  searchVariable: SearchVariableType;
  annualRate?: number;
  accessories?: SimulationServiceItem[];
  serviceSimulation?: any;
  quoteInfo?: any;
  partyInfo?: any;
  assetInfo?: any;
}

export interface SimulationServiceItem extends UniformOdmName {
  productUid: UniformOdmName;
  currency: string;
  flagIncludingAPRC: boolean;
  quantity: number;
  paymentDatesOption: string;
  amount: MoneyAmount;
  calculationMethodOption: string;
  annualRate: number;
  basis: string;
  basisValue: number;
  taxValue: number;
  beneficiaries?: any[];
}

export enum SearchVariableType {
  annualRate = "AnnualRate",
  payment = "Payment",
  outstandingBalanceBefore = "OutstandingBalanceBefore",
  outstandingBalanceAfter = "OutstandingBalanceAfter",
  firstPayment = "FirstPayment",
}

// TODO 35764 remove old object
export interface SimulationResponse extends UniformOdmName {
  isPricingResponse: false;
  financialProductUid: UniformOdmName;
  productUid: UniformOdmName;
  duration: number;
  millage: number;
  price: number;
  firstPayment: number;
  residualValue: number;
  firstPayment1: number;
  firstPayment2: number;
  firstPayment3: number;
  payment1: number;
  payment2: number;
  payment3: number;
  months1: number;
  months2: number;
  months3: number;
}

// Proposal Accessories

export interface ProposalAccessories extends UniformOdmName {
  taxCode?: UniformOdmName;
  party?: AssociatedParty;
  servicePaymentSchedules: ServicePaymentSchedule[];
  label?: string;
  basis?: number;
  basisValue?: number;
  rate?: number;
  duration?: number;
  periodicity?: string;
  taxValue?: number;
  amountWTax?: MoneyAmount;
  amountWoTax?: MoneyAmount;
  accessoryProduct?: UniformOdmName;
  proposalAccessoryCalculationMethodOption?: UniformOdmName;
  proposalAccessoryPaymentDatesOption?: UniformOdmName;
  quantity?: number;
  coverageRation?: number;
  associatedInsuranceBeneficiaries? : any;
}

export interface ServicePaymentSchedule extends UniformOdmName {
  serviceAmountWoTax?: number;
  taxAmount?: number;
  serviceAmountWTax?: number;
}

// Tasks

export interface TaskState {
  tasks: Task[];
  tasksToExport: Task[];
  selectedTask: Task;
  searchTask: SearchTask[];
  gatheringProcess: any;
  nbrReceivedDocuments: string;
  group: any;
  roles?: any;
  offer?: Offer;
  taskDefinition?: TaskDefinition;
  executedAction?: ActionType;
  executedActionEvent?: any;
  formValid?: boolean;
  decisionModule: DecisionState;
  analysisModule: AnalysisState;
  modifyConditionsModule: ModifyConditionsState;
  validateDocumentsModule: ValidateDocumentsState;
  comment?: string;
  printOfferModule: PrintOfferState;
  groups?: any;
  filterByOfferReference?: string;
  checkForm?: boolean | false;
  isFormValid?: boolean | true;
  members?: any;
  actions?: any;
  order?: Order;
  module: TaskModule;
  startOrderModule: StartOrderState;
  validationOrderModule: ValidationOrderState;
  isTaskContinueLoading: boolean;
  isTaskQuitLoading: boolean;
  decisions?: any;
  Openionreserves?: any;
  delegationLvlProcess?: string;
  updateDelegationLvlProcess?: string;
  assignReassign: AssignReassign;
  gatheringProcessOrder?: any;
}

export type AssignReassign = {
  dialog?: boolean;
  teamMember: string;
  taskSelected?: Task;
  selectedTasks: Task[];
};

export enum TaskModule {
  OFFER = "Offer",
  ORDER = "Order",
}

export enum TaskDefinition {
  ENTER_CUSTOMER_DECISION = "STEP_ENTER_CUSTOMER_DECISION", // Customer Accept Decision
  COMPLETE_OFFER_INFO = "STEP_COMPLETE_OFFER_INFO", // Complete customer details
  UPLOAD_DOCUMENTS = "STEP_UPLOAD_DOCUMENTS", // Upload documents
  STEP_START_FINANCING_REQUEST = "STEP_START_FINANCING_REQUEST", // Financing Request
  VALIDATE_DOCUMENTS = "STEP_VALIDATE_DOCUMENTS", // Validate documents
  ENTER_DECISION = "STEP_ENTER_DECISION", // Enter Decision
  // PRINT_CONTRACT = "STEP_PRINT_CONTRACT", // Print Contract
  PRINT_DOCUMENT = "STEP_PRINT_DOCUMENT", // Print Document
  SIGN_CONTRACT = "STEP_SIGN_CONTRACT", // Contract signature
  ANALYSIS = "STEP_START_ANALYSIS", // Analysis Contract
  DELEGATION_LEVEL = "STEP_ENTER_DELEGATION_LEVEL",
  MODIFY_CONDITIONS = "STEP_MODIFY_CONDITIONS", // Modify conditions
  ENTER_OPINION = "STEP_ENTER_OPINION", // Enter opinion

  // Order module
  START_ORDER = "STEP_START_ORDER",
  COMPLETE_PRECONDITIONS = "STEP_COMPLETE_PRECONDITIONS",
  VALIDATE_ORDER = "STEP_SUP_VALIDATION",
  ORDER_COMMISSION = "STEP_ORDER_COMMISSION",
}

export enum ClientTaskDefinition {
  ENTER_CUSTOMER_DECISION = "STEP_ENTER_CUSTOMER_DECISION", // Customer Accept Decision
  COMPLETE_OFFER_INFO = "STEP_COMPLETE_OFFER_INFO", // Complete customer details
  UPLOAD_DOCUMENTS = "STEP_UPLOAD_DOCUMENTS", // Upload documents
  STEP_START_FINANCING_REQUEST = "STEP_START_FINANCING_REQUEST", // Financing Request
  VALIDATE_DOCUMENTS = "STEP_VALIDATE_DOCUMENTS", // Validate documents
  ANALYSIS = "STEP_START_ANALYSIS", // Analysis Contract
  ANALYSIS_ON_GOING = "ANALYSIS_ON_GOING", // ANALYSIS_ON_GOING
  DELEGATION_LEVEL = "STEP_ENTER_DELEGATION_LEVEL",
  ENTER_OPINION = "STEP_ENTER_OPINION", // Enter opinion
  ENTER_DECISION = "STEP_ENTER_DECISION", // Enter Decision
  PRINT_DOCUMENT = "STEP_PRINT_DOCUMENT", // Print Document
  SIGN_CONTRACT = "STEP_SIGN_CONTRACT", // Contract signature
  CUSTACCEPT = "CUSTACCEPT",
  ANALYSIS_ON_HOLD = "ANALYSIS_ON_HOLD",
  ANALYSIS_FINISHED = "ANALYSIS_FINISHED",
  MANSIGNATURE_INPROGRESS = "MANSIGNATURE_INPROGRESS",
  ELECSIGNATURE_INPROGRESS = "ELECSIGNATURE_INPROGRESS",
  MANSIGNATURE_COMPLETED = "MANSIGNATURE_COMPLETED",
  ELECSIGNATURE_COMPLETED = "ELECSIGNATURE_COMPLETED",
  DECISION = "DECISION",
}

export enum AnalysisSectionDefinition {
  PRT_IDT = "PRT-IDT", // Party identity
  PRT_STU = "PRT-STU", // Party situation
  PRT_PROF = "PRT-PROF", // Party profession
  PRT_STF = "PRT-STF", // Party Financila situation
  AST_01 = "AST-01", // Asset
  FIN_01 = "FIN-01", // Financement
  DOC_01 = "DOC-01", // Document
}

export enum ActionType {
  VALIDATE = "VALIDATE",
  CANCEL = "CANCEL",
  NEXT_FIELD = "NEXT_FIELD",
  PREVIOUS_FIELD = "PREVIOUS_FIELD",
  DOWNLOAD_DOCUMENT = "DOWNLOAD_DOCUMENT",
  SEND_DOCUMENT = "SEND_DOCUMENT",
  ANALYSIS = "ANALYSIS",
  ORDER_CONTINUE = "ORDER_CONTINUE",
  DELEGATION_LEVEL = "DELEGATION_LEVEL",
  CANCEL_STEP = "CANCEL_STEP",
}

export interface SearchTask {
  actionDate?: string;
  actionIssueDate?: string;
  status?: any;
  reference?: string;
  clientName?: string;
  commercialName?: string;
  clientReference?: string;
  typeAction?: string;
  group?: string;
  myAction?: boolean;
  roles?: any;
  groups?: any;
  module?: any;
  members?: any;
  actions?: any;
  isUnAssignedTask?: boolean;
  proposalReference?: string;
  customerType?: { id: string; label: string };

}
export interface GroupReturn {
  resourceUid?: string;
  objectType?: string;
  systemUid?: string;
  group?: Group;
}

export interface Group {
  objectType?: string;
  systemUid?: string;
  resourceUid?: string;
}

export interface Task extends UniformOdmName {
  isLoading?: boolean;
  taskId?: string;
  taskName?: string;
  priority?: number;
  creationDate?: string;
  dueDate?: string;
  followUpDate?: string;
  status?: string;
  assignee?: string;
  owner?: string;
  candidateUser?: string;
  candidateGroup?: string;
  variables?: any;
  parentTask?: string;
  taskDefinition?: string;
  taskAnalysis?: string;
  objectType?: string;
  systemUid?: string;
  daaq?: string;
  decision?: any;
  acceptDecision?: any;
  histories?: any;
  opened?: boolean;
  proceed?: boolean;
  associatedParties?: [];
  showPopupDialog?: any;
  selectedAction?: any;
}

export interface TaskSearchCriteria extends UniformOdmName {
  assignee?: string;
  assigneeIn?: string;
  candidateGroups?: string;
  dueDate?: string;
  dueBefore?: string;
  dueAfter?: string;
  createdOn?: string;
  createdBefore?: string;
  createdAfter?: string;
  owner?: string;
  taskDefinition?: string;
  taskDefinitionKeyIn?: string;
  candidateUser?: string;
  daaq?: string;
  entityId?: string;
  searchVariables?: string;
  initializeVariables?: string;
  actions?: string;
  unAssigned?: boolean;
  includeAssignedTasks?: boolean | true;
  offset?: any;
  limit?: any;
  sortBy?: any;
  sortOrder?: any;
  processDefinitionKeyIn?:string;
  customerType?:string;
}

export interface ProcessInitialisationContext extends UniformOdmName {
  processDefinitionId: string;
  variables: string;
}

export interface TaskActionEvent extends UniformOdmName {
  taskId: string;
  userId: string;
}

export interface TaskExecuteAction extends UniformOdmName {
  taskId: string;
  variables?: any;
}

type PartyData = Person | Organization;

export interface QuoteInfo {
  status: string;
}

export interface Items {
  type: string;
  value: string | PartyInfo[] | QuoteInfo;
  valueInfo: any;
}

export interface Variables {
  businessKey?: Items;
  quoteInfo: Items;
  partyInfo: Items;
}

export interface StartOrderState {
  bind: any;
  decisionDate?: string;
  comment?: string;
  decision?: any;
}
export interface ValidationOrderState {
  bind: any;
  decisionDate?: string;
  comment?: string;
  decision?: any;
}

// Decision

export interface DecisionState {
  decision?: Decision;
  acceptDecision?: Decision;
  decisionDetails?: any;
  selectedDecision?: any;
  selectedDecisionStatus?: any;
  connectedUser?: any;
}
export interface AnalysisState {
  isLoading?: boolean;
  questionnaire?: Questionnaire;
  questionnairegatheringItem?: any;
  gatheringQuestions?: Question[];
  selectedAnswersOptions?: Record<string, AnswerOption>;
  ratiosByCategories?: any;
  analysis?: Analysis;
  reserves?: any[];
  evaluatedReserves?: any[];
  associatedAnalysisProcess?: any;
  AlgorithmeChanged?: boolean;
  opinionreserves?: any[];
  errors?: any;
  analysisDataInfo?: any;
}

export interface QuestionnaireCategories {}

export interface Decision extends UniformOdmName {
  reference?: string;
  decisionDate: string;
  decisionData?: string;
  decisionEntity?: UniformOdmName;
  decisionMakerId?: UniformOdmName;
  status?: UniformOdmName;
  comment: string;
  decisionReserves?: Reserve[];
  decision: any;
  decisionReasons?: [];
  daaq: string;
}

export interface Analysis extends UniformOdmName {
  businessData: any;
  daaq?: string;
  reference?: string;
  owner?: {};
  analysisEntity?: UniformOdmName;
  analysisData?: any;
  analysisScore?: UniformOdmName;
  status: AnalysisProcessStatus;
  assessment: UniformOdmName; // to be checked
  ratios: Ratio[];
  analysisRatings?: AnalysisRating[]; // in progress
  historyRating: any;
  score?: any;
  opinionQualifications?: any; // in progress
}

export interface AnalysisRating extends RatingEvaluation {
  validity?: any;
}

export interface AnalysisProcessStatus extends UniformOdmName {
  defaultValue: boolean;
  internalCode: AnalysisProcessStatusEnum; // in progress
}

export enum AnalysisProcessStatusEnum {
  CREATED = "CREATED",
  IN_PROGRESS = "IN_PROGRESS",
  ON_HOLD = "ON_HOLD",
  CLOSED = "Closed",
  ONHOLD = "ONHOLD",
  START = "START",
}

export enum ElectronicSignKeys {
  ELEC_SIGN_POSITION = "ELEC_SIGN_POSITION",
  invitationSubject = "Electronic Signature",
  invitationMessage = "Electronic Signature",
}

export enum SignatureProcessStatusEnum {
  CREATED = "CREATED",
  MANSIGNATURE_INPROGRESS = "MANSIGNATURE_INPROGRESS",
  ELECSIGNATURE_INPROGRESS = "ELECSIGNATURE_INPROGRESS",
  MANSIGNATURE_COMPLETED = "MANSIGNATURE_COMPLETED",
  ELECSIGNATURE_COMPLETED = "ELECSIGNATURE_COMPLETED",
}

export enum SignatureMethodEnum {
  MANSIGNATURE = "MANUAL",
  ELECSIGNATURE = "ELECTRONIC",
}
export interface Assessment extends UniformOdmName {
  contextType: Qualifier;
  applicationName: boolean;
  validity: DateRange;
  analysisRatingRule: null;
  analysisScoreRule: null;
  // ratioParams: RatioParam;// in progress
}
// export interface RatioParam {

//   dataInfo: string;
//   dataFormula: string;
//   dataInternalService: string;
//   dataExternalService: string;
//   ratioEvalRule: null;
//   assessment: Assessment;

// }

// export interface RatioValue extends UniformOdmName {
//   value: string;
//   ratioParam: RatioParam;// to be checked
//   ratioEvaluations: RatioEvaluation;// to be checked
// }

export type Process = UniformOdmName;
// export interface RatioEvaluation extends UniformOdmName {
//   value: string;// to be checked
//   type: string;// to be checked
//   decision: string;// to be checked
//   comment: any;// to be checked
//   analysisRatio: RatioValue;// to be checked
// }

export interface Rating {}

export interface OpinionMaker {
  opinion: Opinion;
  comment: any;
  opinionQualifications: OpinionQualification[];
}

export interface Opinion {
  resourceUid: OpinionEnum | null;
}
export enum OpinionEnum {
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export type OpinionQualification = {
  comment: any;
  qualificationType: any;
};

export type OpinionQualificationType = {};
export interface Reserve {
  label: string;
  selected: boolean;
}

//

export interface ValidateDocumentsState {
  gatheringProcessList: GatheringProcess[];
}

// Supporting Document

export interface FileContent extends UniformOdmName {
  originalFileName?: string;
  format?: string;
  md5Hash?: string;
  metadata?: Metadata[];
  relations?: {
    items: [
      {
        qualifier: {
          rawValue: string | null;
        };
        objectUON: UniformOdmName;
      }
    ];
  };
  content?: unknown;
}

export interface Metadata {
  key: string;
  value: string;
}

export interface DocumentUpload extends UniformOdmName {
  format: string;
  originalFileName: string;
}

export enum SupportingDocumentStatus {
  VALID = "VALID",
  REJECTED = "REJECTED",
  OVERWRITE = "OVERWRITE",
  CANCELLED = "CANCELLED",
  QUALIFIED = "QUALIFIED",
}

export interface GatheringProcess {
  supportingDocumentsAgrouped?: Record<string, supportingDocuments[]>;
  config?: any;
  resourceUid: string;
  objectType: string;
  systemUid: string;
  businessData: string;
  contextType: string;
  assessmentContext: string;
  status: status;
  supportingDocuments: supportingDocuments[];
  gatheringProcessItem: any[];
  startProcessTimestamp: string;
  endProcessTimestamp: string;
  lastUpdateTimestamp: string;
  processValidUntil: string;
}
export interface supportingDocuments {
  config?: any;
  resourceUid: string;
  objectType: string;
  systemUid: string;
  businessData: string;
  documentFamily: documentFamily;
  manual: boolean;
  manualCreationComment: string;
  targetEntity: UniformOdmName & {
    reference: string;
  };
  status: status;
  proofLevel: string;
}
export interface documentFamily {
  objectType: string;
  systemUid: string;
  resourceUid: string;
}
export interface status {
  objectType: string;
  systemUid: string;
  resourceUid: string;
}
export interface receivedFile {
  resourceUid: string;
  objectType: string;
  systemUid: string;
  businessData: string;
  original: boolean;
  receptionDate: string;
  qualificationDate: string;
  fileReference: string;
  status: status;
  content: any[];
}

export interface evaluateProposalResponse extends UniformOdmName {
  defaultResidualValue: number;
  minResidualValue: number;
  maxResidualValue: number;
}

// Financing

export enum FinancingPhase {
  SIMULATION = "simulation",
}
export interface FinancingState {
  isSimulationRefreshing?: boolean;
  simulationChanged?: boolean;
  formModule: FormState;
  scalesModule: ScalesState;
  serviceModule: ServiceState;
  packs: ProductSummary[];
  financingProducts: ComboBoxItem[];
  categoryClassSelected?: string;
  currentState: FinancingStateEnum;
  previousState?: FinancingStateEnum;
  proposalAssetTotalWithTax?: number;
  proposalAssetItemsTotalWoTax?: number;
  proposalGuaranteeModule?: ProposalGuaranteeState;
}

export enum FinancingStateEnum {
  INITIAL = "INITIAL",
  CALCULATED = "CALCULATED",
  SERVICE_DETAIL = "SERVICE_DETAIL",
  SAVED = "SAVED",
  GLOBAL_PAYMENT_SCHEDULE = "GLOBAL_PAYMENT_SCHEDULE",
  EXPERT = "EXPERT",
}

export interface FormState {
  sliders?: any;
}

export interface ScalesState {
  scales: FinancingProductPackInfo[];
  scaleSelected?: FinancingProductPackInfo;
  scalesItemsSelected?: FinancingOfferItem[];
  categorySelected?: string;
}

export interface ServiceState {
  services: SimpleServiceProductItem[] | PartyInsuranceProductItem[] | any;
  calculationLoading: boolean;
  calculationPendent: boolean;
}

export interface ProductSummary extends UniformOdmName {
  productUid: UniformOdmName;
  productName: string;
  category: string;
  categoryClass: string;
  categoryClassList: string[];
  index?: number;
  activityType?: string;
  classifications: any;
}

export interface FinancingProductPackInfo extends UniformOdmName {
  financialSituation?: any;
  calculationResult?: any;
  financingProductPackUid?: string;
  financialProfileUid: string;
  paymentTermsOption: string;
  paymentDatesOption: string;
  periodRateOption: string;
  taxOnInterest?: string;
  investmentBasis?: string;
  productName: string;
  category: string;
  categoryClass: string;
  productTax?: any;
  items:
    | FinancingOfferItem[]
    | SimpleServiceProductItem[]
    | PartyInsuranceProductItem[]
    | any;
  scalePresentation: ScalePresentation;
  categoryClassList: string[];
  activityType?: string;
  scalePresentationDefault?: ScalePresentation;
  periodBetween2Installments?: ElapseTime;
  minNumberGracePeriodTerms?: any;
  maxNumberGracePeriodTerms?: any;
  defaultNumberGracePeriodTerms?: any;
  gracePeriodCalculationMethodOption?: any;
}

export interface ScalePresentation {
  financingOfferResourceUid?: string;
  payment?: string;
  monthly?: string;
  loading: boolean;
  visible: boolean;
  loadingError?: boolean;
  index?: number;
  items?: ScalePresentationItem[];
  sliders?: any;
  services?: [];
  servicePartyInsurance?: any;
  proposalResourceUid?: any;
  proposalItemResourceUid?: any;
  rateFinancingTax?: number;
  monthlyPresentation?: string;
  periodBetween2Installments?: ElapseTime;
  gracePeriodPresentation?: string;
  gracePeriod?: string;
  period?: string;
}
export interface ScalePresentationItem {
  label: string;
  value: string;
}

export interface FinancingProductPackItem extends UniformOdmName {
  productUid?: string;
  name?: string;
  status?: string;
  tax?: string;
}

export interface FinancingOfferItem extends FinancingProductPackItem {
  objectType: "odm.product.financingproductpackitem.financingofferitem";
  numberOfPaymentTerms?: number;
  periodBetween2Installments?: ElapseTime;
  maxDuration?: ElapseTime;
  minDuration?: ElapseTime;
  typeRate?: string;
  annualRate?: number;
  minAnnualRate?: number;
  maxAnnualRate?: number;
  basisRate?: string;
  payment?: MoneyAmount;
  firstPayment?: MoneyAmount;
  minFirstPayment?: MoneyAmount;
  maxFirstPayment?: MoneyAmount;
  lastPayment?: MoneyAmount;
  outstandingBalanceBefore?: MoneyAmount;
  outstandingBalanceAfter?: MoneyAmount;
  downPayment?: MoneyAmount;
  minDownPayment?: MoneyAmount;
  maxDownPayment?: MoneyAmount;
  minNumberGracePeriodTerms?: number;
  maxNumberGracePeriodTerms?: number;
  defaultNumberGracePeriodTerms?: number;
  gracePeriodCalculationMethodOption?: UniformOdmName;
}

export interface AccessoryProductItem extends FinancingProductPackItem {
  currency?: string;
  paymentDatesOption?: string;
  qualification?: string;
  amount?: MoneyAmount;
  amountMin?: MoneyAmount;
  amountMax?: MoneyAmount;
  calculationMethodOption?: string;
  annualRate?: number;
  annualRateMin?: number;
  annualRateMax?: number;
  basis?: string;
}

export interface ServiceProductItem extends AccessoryProductItem {
  flagIncludingAPRC?: boolean;
  originationCommissionProductItem?: UniformOdmName;
  config?: any;
}

export interface SimpleServiceProductItem extends ServiceProductItem {
  objectType: "odm.product.financingproductpackitem.accessoryproductitem.serviceproductitem.simpleserviceproductitem";
}

export interface PartyInsuranceProductItem extends ServiceProductItem {
  objectType: "odm.product.financingproductpackitem.accessoryproductitem.serviceproductitem.insuranceproductitem.partyinsuranceproductitem";
}

// Financing Requests

export interface ProductSelectionParameters extends UniformOdmName {
  applicationName: string;
  context: string;
  maxResults: number;
  financingProductPackUid?: string;
  partyInfo: PartyInfo[];
  assetInfo: AssetInfo[];
  accessoryProductInfo: AccessoryProductInfo[];
  quoteInfo: string;
}

export interface PartyInfo extends UniformOdmName {
  partyData: string | PartyData;
}

export interface AssetInfo extends UniformOdmName {
  assetData: string;
}

export interface AccessoryProductInfo extends UniformOdmName {
  currency?: string;
  quantity?: number;
  calculationMethodOption?: string;
  paymentDatesOption?: string;
  basis?: string;
  basisValue?: number;
  amount?: MoneyAmount;
  annualRate?: number;
  servicePrefixQualifier?: DataQualifier;
}

export interface DataQualifier {
  rawValue?: string;
}

// Financing Calculation

export interface CalculationResponse extends UniformOdmName {
  residualValueWTax: number;
  residualValueWoTax: number;
  financialProductUid: UniformOdmName;
  productUid: UniformOdmName;
  duration: number;
  millage: number;
  price: number;
  firstPayment: number;
  firstPaymentWTax: number;
  firstPaymentWoTax: number;
  downPayment: number;
  residualValue: number;
  result: number;
  precision: number;
  sumOfTotalPayments: MoneyAmount;
  sumOfFinancialPayments: MoneyAmount;
  sumOfInterests: MoneyAmount;
  sumOfServices: MoneyAmount;
  creditCost: MoneyAmount;
  aprc: number;
  air: number;
  paymentSchedules: PaymentSchedule[];
}

export interface PaymentSchedule extends UniformOdmName {
  stepNo: string;
  dueDate: Date;
  periodStartDate: Date;
  periodEndDate: Date;
  outstandingBalanceBefore: number;
  outstandingBalanceAfter: number;
  paidPrincipal: number;
  paidServices: number;
  periodRate: number;
  nominalRate: number;
  interest: number;
  disbursement: number;
  financialInstallmentWoTax: number;
  financialInstallmentWTax: number;
  totalInstallment: number;
  items:
    | CommissionPaymentScheduleItem[]
    | InstallmentPaymentScheduleItem[]
    | ServicePaymentScheduleItem[]
    | any;
}

export interface PaymentScheduleItem extends UniformOdmName {
  reference: string;
  amountValue: number;
}

export interface CommissionPaymentScheduleItem extends PaymentScheduleItem {
  objectType: "odm.simulation.paymentscheduleitem.commissionpaymentscheduleitem";
}

export interface InstallmentPaymentScheduleItem extends PaymentScheduleItem {
  objectType: "odm.simulation.paymentscheduleitem.installmentpaymentscheduleitem";
}

export interface ServicePaymentScheduleItem extends PaymentScheduleItem {
  objectType: "odm.simulation.paymentscheduleitem.servicepaymentscheduleitem";
}

export interface ModifyConditionsState {
  decisionStep?: Decision[];
  supportingDocumentsManual?: SupportingDocManual[];
}

// Middle Office

export interface MiddleOfficeState {
  offer?: Offer;
  section?: string;
  gatheringProcessList?: GatheringProcess[];
  historiesStep?: Task[];
  showService?: boolean;
  decisionStep?: Decision[];
  showSummary: boolean;
  summaryPanel: boolean;
  proposalScale?: any;
  ProposalGuaranteeSelected?: any;
  variablesVisible?: boolean;
  statusHistoryVariableSelected?: StatusHistoryVariables;
  openopeAnalysisReference?: boolean;
  analysisReference?: string;
  calculate: any;
  preConditionStep?: any;
  preConditionDetail?: any;
  preConditionDetailVisible?: boolean;
}

// Print Offer Action

export interface PrintOfferState {
  templates: NoticeTemplate[];
  previewTemplate?: any;
  visualizedTemplate?: NoticeTemplate;
  emailSendList: string[];
  showSendTemplate?: boolean;
  emailSendListOption?: SelectComboBox;
  body: any;
  showButtonsPreview: boolean;
  showButtonValidate: boolean;
  showLoadingDownload?: boolean;
}

export interface NoticeTemplateSelectionContext {
  applicationName: string;
  context: string;
  maxResults: number;
  dataInfo: string;
  partyInfo?: string;
  quoteInfo?:string;
  signatureMethod?:string;
}

export interface NoticeTemplate extends UniformOdmName {
  selected?: any;
  validity: DateRange;
  fileName: string;
  filePath: string;
  sender?: string;
  recipient?: string;
  body?: string;
  isPack?: boolean;
  items?: PackNoticeTemplateInfo[];
}

export interface PackNoticeTemplateInfo extends UniformOdmName {
  validity: DateRange;
  fileName: string;
  filePath: string;
  isPack: boolean;
  selected?: any;
  documentOrder?: number;
  itemTemplate?: UniformOdmName;
  items: PackNoticeTemplateItemInfo[];
}

export interface PackNoticeTemplateItemInfo {
  validity: DateRange;
  fileName: string;
  filePath: string;
  qualification: string;
  config?: any;
  selected: boolean;
}

export interface NoticeMessage extends UniformOdmName {
  dataInfo?: string;
  clientApplication?: UniformOdmName;
  parameters?: any;
  items?: PackNoticeTemplateInfo[];
}
export interface NoticeContent extends UniformOdmName {
  content?: string;
  clientApplication?: UniformOdmName;
  parameters?: any;
}
export interface EmailMessage extends UniformOdmName {
  clientApplication?: UniformOdmName;
  emailTemplate?: UniformOdmName;
  businessData?: any;
  relatedObjects?: any;
  parameters?: any;
  attachments?: any[];
}
export interface Attachment extends UniformOdmName {
  attachment?: UniformOdmName;
  label?: string;
  originalFileName?: string;
  format?: string;
  content?: string;
}

export interface SummaryPanelState extends UniformOdmName {
  offer?: Offer;
  proposalScale?: any;
  order?: Order;
  gatheringProcessOrder?: any;
}

export interface DAAQ {
  daaqResourceUid?: string;
  daaq: string;
}

export interface LeaseForgeUser extends User {
  username?: string;
  applicationName?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  associatedParty?: AssociatedParty;
  accountNotExpired?: boolean;
  accountNotLocked?: boolean;
  credentialsNotExpired?: boolean;
  enabled?: boolean;
  defaultLanguage?: string[2]; // will be replace with EnumList
  parsonalAuthorities?: any;
  userGroup?: any; // User.group
  personalDAAQs?: DAAQ[];
}
// communication
export enum MessageStatusEnum {
  READ = "READ",
  NEW = "NEW",
  CLOSED = "CLOSED",
}
export type MessageStatus = MessageStatusEnum; // MessageStatusEnum | string;

export enum MessageTypeEnum {
  NOTIFICATION = "NOTIFICATION",
  MESSAGE = "MESSAGE", // as default
  CHAT = "CHAT",
}
export type MessageType = MessageTypeEnum; // MessageStatusEnum | string;

export enum contextTypeComEnum {
  OFFER = "DEMANDE",
  PARTY = "TIERS",
}
export type contextTypeCom = contextTypeComEnum; // used for communication module to referenced context type (OFFER/PARTY)

export type MessageReference = {
  reference?: number;
};

export interface references {
  references: MessageReference[];
}

export type MessageSubject = {
  id?: string;
};
export interface MessageObjects {
  subjects: UniformOdmName[];
  users: User[];
  groups: Group[];
}

export interface recipient {
  recipient?: UniformOdmName | null;
}

export interface _parameters {
  Context?: string;
  Entity_Reference?: string;
  Entity_Status?: string;
}

export interface comMessageTemplate extends UniformOdmName {
  daaq?: string;
  sender?: string;
  priorty?: boolean;
  subject?: UniformOdmName | null;
  type?: UniformOdmName | null;
  recipients?: recipient[];
  body?: string;
  relatedObjects?: UniformOdmName | null;
  clientApplication?: UniformOdmName | null;
  status?: UniformOdmName | null;
}

/*export interface Message extends comMessageTemplate {
  _parameters?: _parameters
  _messageTemplate?: any
}*/

export interface MessageT extends comMessageTemplate {
  //_message?: comMessageTemplate
  _parameters?: _parameters;
  _messageTemplate?: any;
}

export interface Message {
  priority: any;
  message?: MessageT;
}

//notification
export interface metadata extends UniformOdmName {
  businessData?: any;
  daaq?: string;
  key: string;
  value?: string;
}
export interface Notification extends comMessageTemplate {
  status?: UniformOdmName | null;
  businessData?: any;
  communicationDate?: any;
  metadatas?: metadata[];
  replyMsgParent?: any;
  transferMsgParent?: any;
}

export interface Messages {
  message: Message[];
}

export interface Notification {
  notification?: Notification;
}

export interface Notifications {
  notifications: Notification[];
  countNotifications: number;
  msgUID: string;
  msgStatusTabs: string[];
  msgSelectedStatusTab: string;
}

export const messageTemplate = {
  objectType: "odm.contactmechanism.messagetemplate",
  systemUid: "odm-contactmechanism",
  resourceUid: "TEMPLATE_MSG",
};

export const senderUid = {
  objectType: "odm.user.leaseforgeuser",
  systemUid: "odm-user",
  resourceUid: "", //this.$store.state.authModule?.user.userId,
};

export const subject = {
  objectType: "odm.contactmechanism.messagesubject",
  systemUid: "odm-contactmechanism",
  resourceUid: "", //this.subject,
};

export const clientAppMsg = {
  objectType: "odm.contactmechanism.contactmechanismclient",
  systemUid: "odm-contactmechanism",
  resourceUid: "", //"68815", //*
};

export const systemUidOdmContactmechanism = `odm-contactmechanism`; // systemUid
export const systemUidOdmUser = `odm-user`; //systemUid1
export const objectTypeUser = `odm.user.leaseforgeuser`;
export const objectTypeGroup = `odm.user.securitygroup`;

export const typeMsg = {
  objectType: "odm.contactmechanism.messagetype",
  systemUid: "odm-contactmechanism",
  resourceUid: "MESSAGE",
};

export const objectType_odmTransferReplyMsg =
  "odm.contactmechanism.communicationrequest.message";
export interface transferMsg {
  messageTemplate: UniformOdmName | undefined; // const
  sender: string;
  senderUid: UniformOdmName;
  priority: boolean;
  subject: UniformOdmName | null;
  type: UniformOdmName | null;
  body: string;
  recipients: recipient[]; //UniformOdmName[]
  parameters: _parameters;
  relatedObjects: any | undefined; //?
  clientApplication?: UniformOdmName;
  objectType: string; // const
  systemUid: string;
  daaq: string;
  resourceUid: string;
}

export class userRecipient {
  icon: string;
  label: string;
  status: string;
  color: string;
  doneIcon?: string;
  constructor(
    icon: string,
    color: string,
    label: string,
    status: string,
    doneIcon?: string
  ) {
    this.icon = icon;
    this.label = label;
    this.status = status;
    this.color = color;
    this.doneIcon = doneIcon;
  }
}
export interface SimpleFinancingParameters extends UniformOdmName {
  currency: string;
  paymentDatesOption: string;
  periodRateOption: string;
  paymentTermsOption: string;
  annualRate: number;
  numberOfPaymentTerms: number;
  payment: CalculationAmount;
  periodBetween2Installments: CalculationPeriod;
  taxOnInterest: string;
  investmentBasis: string;
  productTax: string;
  installmentPrefixQualifier: DataQualifier;
  partyInfo: PartyInfo[];
  assetInfo: AssetInfo[];
  accessoryProductInfo: AccessoryProductInfo[];
  quoteInfo: string;
}
export interface Beneficiaries extends UniformOdmName {
  _controls: any;
  partyUid?: string;
  partyName?: SelectComboBox;
  partyBirthDate?: string;
  partyRole?: any;
  amount?: MoneyAmount;
  annualRate?: number;
  annualRateMin?: number;
  annualRateMax?: number;
  basis?: string;
  businessData?: any;
  daaq?: string;
}

export interface PartyInsuranceProductItem extends UniformOdmName {
  beneficiaries?: Beneficiaries[];
  flagIncludingAPRC?: boolean;
  originationCommissionProductItem?: UniformOdmName;
  currency?: string;
  paymentDatesOption?: string;
  qualification?: string;
  amount?: MoneyAmount;
  calculationMethodOption?: string;
  annualRate?: number;
  annualRateMin?: number;
  annualRateMax?: number;
  basis?: string;
  productUid?: string;
  name?: string;
  status?: string;
  tax?: string;
}

export interface IHistoricMessage {
  resourceuid: string;
  date: string;
  sender: string;
  priority: boolean;
  subject: string;
  status: string;
  body: string;
  replymsgparent: string;
  transfermsgparent: string;
  recipientname: string;
  recipient_resourceuid: string;
}

export interface ScaleSearch {
  name?: string;
  reference?: string;
  validity: Validity;
  financialProfile?: ComboBoxItem;
  status?: ComboBoxItem;
  category?: ComboBoxItem[];
  class?: ComboBoxItem[];
}

export interface AssetSearch {
  reference?: string;
  name?: string;
  type?: ComboBoxItem;
  brand?: ComboBoxItem;
  model?: ComboBoxItem;
  family?: ComboBoxItem;
  catalog?: ComboBoxItem;
  status?: ComboBoxItem;
  supplier?: Third;
  inventory?: boolean;
}

export interface OrderAssetSearch {
  brand?: ComboBoxItem;
  model?: ComboBoxItem;
  color?: ComboBoxItem;
  activity?: ComboBoxItem;
}

export interface GoodFeature {
  family?: ComboBoxItem;
  subfamily?: ComboBoxItem;
  brand?: ComboBoxItem;
  model?: ComboBoxItem;
  finishing?: ComboBoxItem;
  bodywork?: ComboBoxItem;
  numberOfPlaces?: number;
  numberOfDoors?: number;
  numberOfCylinders?: number;
  energy?: ComboBoxItem;
  fiscalPower?: number;
  cO2Emissions?: number;
  urbanConsumption?: number;
  extraUrbanConsumption?: ComboBoxItem;
  mixedConsumption?: number;
}

export interface QcHeader {
  qc: {
    queryId: string;
    offset: number;
    limit: number;
    parameters?: unknown;
    otherFilters?: unknown;
  };
}

export interface GeneralData extends UniformOdmName {
  name: string;
  status?: ComboBoxItem | string;
  validity?: Validity;
  classifications?: Classifications[];
  financialProfile?: ComboBoxItem;
  financialProfileOptions?: {
    validity?: Validity;
    paymentTermsOption?: UniformOdmName;
    paymentDatesOption?: UniformOdmName;
    periodRateOption?: UniformOdmName;
  };
  depot?: boolean;
  reference: string;
}

export interface SimpleFinancialOffer {
  minDuration?: number;
  maxDuration?: number;
  periodBetween2InstallmentsDuration?: number;
  periodBetween2InstallmentsUnit?: ComboBoxItem;
  numberOfPaymentTerms?: any;
  currency?: string;
  typeRate?: ComboBoxItem;
  rateDefault?: number;
  rateMin?: number;
  rateMax?: number;
  basisRate?: string;
  firstPaymentDefault: number | null;
  firstPaymentMin?: number | null;
  firstPaymentMax?: number | null;
  downPayment?: number | null;
  maxDownPayment?: number | null;
  minDownPayment?: number | null;
  minNumberGracePeriodTerms?: number;
  maxNumberGracePeriodTerms?: number;
  defaultNumberGracePeriodTerms?: number;
  gracePeriodCalculationMethodOption: ComboBoxItem | null;
  lastPayment?: number;
  outstandingBalanceBefore?: number;
  outstandingBalanceAfter?: number;
  payment?: number;
  advancedConfigurationToggle: boolean;
  firstPaymentToggle?: boolean;
  downPaymentToggle?: boolean;
  gracePeriodToggle?: boolean;
}

export interface FinancialElement extends UniformOdmName {
  name: string;
  validity?: Validity;
  tax?: ComboBoxItem;
  status?: ComboBoxItem | string;
  simpleFinancialOffer?: SimpleFinancialOffer;
  reference?: string;
}

export interface ServiceBenefit extends UniformOdmName {
  serviceType?: ComboBoxItem;
  name?: string;
  validity: Validity;
  status?: ComboBoxItem | string;
  paymentType?: ComboBoxItem;
  tax?: ComboBoxItem;
  apcr: boolean;
  required: boolean;
  accessoryProductRule: {
    currency?: string;
    amount?: number;
    amountMin?: number;
    amountMax?: number;
    calculationMethodOption?: ComboBoxItem;
    annualRate?: number;
    annualRateMin?: number;
    annualRateMax?: number;
    basis?: ComboBoxItem;
    advancedConfigurationToggle?: boolean;
  };
  advancedPackageFeatureRule: {
    advancedConfigurationToggle?: boolean;
    packageFeatureQualification?: string;
    rule?: any;
  };
  rule?: any;
  reference?: string;
}

export type ServiceBenefitWIndex = ServiceBenefit & {
  index?: number;
  rule?: any;
};

export interface ScaleManagement extends UniformOdmName, DAAQ {
  generalData?: GeneralData;
  financialElement?: FinancialElement;
  serviceBenefitSelected?: ServiceBenefitWIndex;
  services: Array<ServiceBenefitWIndex>;
  commissionSelected?: ServiceBenefitWIndex;
  commissions: Array<ServiceBenefitWIndex>;
  classifications?: Array<Classifications>;
}

export interface GenerateInfo {
  selectedFiles?: any[];
  reference?: string;
  description?: string;
  validity?: Validity;
  status?: string;
  type?: string;
}
export interface ItemPoposal {
  selectedFiles?: any[];
  reference?: string;
  description?: string;
  validity?: Validity;
  status?: string;
  mandatory?: boolean;
  priceWoTax?: number;
  typeOfTax?: string;
}
export interface PriceDetails {
  currency?: string;
  price?: number;
  taxeType?: string;
  remise?: number;
  validity?: Validity;
}
export interface GoodFeesServices {
  reference?: string;
  description?: string;
  validity?: Validity;
  typeTax?: string;
  required?: boolean;
}
export interface GarantieDetails extends DAAQ, UniformOdmName {
  businessData?: any;
  reference?: string;
  name?: string;
  validity?: Validity;
  status?: any;
  qualifier?: any;
  tax?: any;
  feature?: Feature[];
  classifications?: Classifications[];
  role?: any;
  partyReference?: UniformOdmName;
  evaluationMethod?: SelectComboBox | string;
  basis?: string;
  amount?: CalculationAmount;
  rate?: number;
  underlyingType?: ComboBoxItem;
  commissionFlag?: boolean;
  garantietype?: any;
  mode?: string;
}

export interface ProposalGuaranteeState {
  proposalGuarantees: Array<ProposalGuarantee>;
  proposalGuaranteeSelected?: ProposalGuarantee;
  productGuaranteeSelected?: IGuaranteeProduct;
  openGuarantee?: boolean;
}

export type ProposalGuarantee = UniformOdmName & {
  description?: string;
  label?: string;
  basisValue?: number;
  rate?: number;
  amount: MoneyAmount;
  basis?: UniformOdmName;
  productGuarantee?: IGuaranteeProduct;
  associatedParty: IAssociatedParty | UniformOdmName;
  productFinancingBasis?: UniformOdmName;
  associatedGuaranteeUnderlying?: Array<AssociatedGuaranteeUnderlyingAssets>;
} & {
  index: number;
  associatedPartyFlag?: boolean;
};

export type IGuaranteeProduct = UniformOdmName & {
  evaluationMethod?: UniformOdmName;
  amount?: MoneyAmount;
  basis?: ComboBoxItem;
  rate?: number;
  role?: UniformOdmName;
  partyReference?: UniformOdmName;
} & {
  name?: string;
  reference?: string;
  guaranteeType?: ComboBoxItem | string;
  commissionFlag?: boolean;
  underlyingType?: ComboBoxItem | string | UniformOdmName;
};

export interface AssociatedGuaranteeUnderlyingAssets extends UniformOdmName {
  label?: string;
  value?: string;
  proposalAsset?: UniformOdmName;
}

export interface IAssociatedParty extends UniformOdmName {
  party: UniformOdmName | ComboBoxItem;
  role?: UniformOdmName | AssociatedPartyRole;
}

export interface Criteria {
  label: string;
  dataType: string;
  dataTypeAttribute: string;
  attribute: string;
  parentEntity: string;
  object: string;
  expression: string;
}
export interface InputHeader {
  label: string;
  dataType: string;
  attribute: string;
  externalId: string;
  defaultValue: string;
  assesmentKey: string;
}

export interface Rule {
  identifier: string;
  value: any;
  ruleId: string | number;
}

export interface RuleSetState {
  ruleSet: RuleSet;
  selectedBuisinessRule: businessRule | null;
  selectedBuisinessRuleItem: childBusinessRuleItem | null;
  briResults: any;
}

export interface QueryBuilderState {
  selectedModule: string;
  isSaving: boolean;
  criterias: Criteria[];
  queryBuilder: any;
  isEditingBri: boolean;
}
export interface RuleSet {
  operatorIdentifier: string;
  children: Array<RuleSet | Rule>;
}

export interface OperatorDefinition {
  identifier: string;
  name: string;
}

export interface RuleDefinition {
  identifier: string;
  name: string;
  component: any | string;
  initialValue?: any;
}

export interface QueryBuilderConfig {
  operators: OperatorDefinition[];
  rules: RuleDefinition[];
  colors?: string[];
  dragging?: SortableOptions;
}

export interface AvailabilityState {
  isLoading?: boolean;
  openAvailability: boolean;
  availability: Availability;
}

export interface AvailabilitySearch {
  name?: string;
  validity: Validity;
  context?: string;
}

export interface Availability extends UniformOdmName, DAAQ {
  applicationName: string;
  validity?: Validity;
  context?: string;
  availableCategories?: AvailabilityCategorie;
}

export interface AvailabilityCategorie {
  category?: string;
  class?: string;
  priority?: number;
  advancedConfigurationToggle: boolean;
}

export interface GroupOperatorSlotProps {
  currentOperator: string;
  operators: OperatorDefinition[];
  updateCurrentOperator: (newOperator: string) => void;
}

export interface GroupCtrlSlotProps {
  rules: RuleDefinition[];
  addRule: (newRule: string) => void;
  newGroup: () => void;
}

export interface RuleSlotProps {
  ruleComponent: any | string;
  ruleData: any;
  updateRuleData: (newData: any) => void;
}

export interface QueryBuilderGroup extends ComponentInternalInstance {
  selectedOperator: string;
  depth: number;
  trap: ((position: number, newChild: RuleSet | Rule) => void) | null;
  children: Array<RuleSet | Rule>;
}

export interface ComponentRegistration {
  component: QueryBuilderGroup;
  ev: RuleSet;
  adding: boolean;
  affectedIdx: number;
}

// Buisiness Rule
export interface childInputHeader {
  label: string;
  dataType: string;
  attribute: string;
  externalId: string;
  defaultValue: string;
  assessmentKey: string;
}

export interface childOutputHeader {
  label: string;
  externalId: string;
  defaultValue: string;
  businessRuleResultType: string;
}

export interface childBusinessRuleItem {
  externalId: string;
  evaluationOrder: number;
  childBusinessRuleResult: childBusinessRuleResult[];
  childBusinessRuleAssessment: childBusinessRuleAssessment[];
}

export interface childBusinessRuleResult {
  result: string;
  externalId: string;
  outputHeader: childOutputHeader;
}
export interface childBusinessRuleAssessment {
  rule: string;
  externalId: string;
  inputHeader: childInputHeader;
}
export interface businessRule {
  label: string;
  externalId: string;
  failIfNoResult: boolean;
  childInputHeader?: childInputHeader[];
  childOutputHeader?: childOutputHeader[];
  childBusinessRuleItem: childBusinessRuleItem[];
  returnMultipleResults: boolean;
}

// Print Document

export interface PrintDocumentState {
  showButton: boolean;
  show: boolean;
  reference?: string;

  //    Print Action

  templates: NoticeTemplate[];
  previewTemplate?: any;
  visualizedTemplate?: NoticeTemplate;
  emailSendList: string[];
  showSendTemplate?: boolean;
  emailSendListOption?: SelectComboBox;
  body: any;
  showButtonsPreview: boolean;
  showButtonValidate: boolean;
  showLoadingDownload?: boolean;
  printDocumentConfirmationDialogState?: ConfirmationDialogState;
  fileDetailFileId: string;
  fileContent: string;
  isLoading?: boolean;
}

// Controls
export enum HomePageRoles {
  HOME_PAGE_HOME = "home",
  HOME_PAGE_OFFER = "demand/list",
  HOME_PAGE_ORDER = "order",
  HOME_PAGE_THIRD = "third/list",
  HOME_PAGE_TASKS = "task/list",
  HOME_PAGE_AVAILABILITY = "availability/list",
  HOME_PAGE_SCALE = "scale/list",
  HOME_PAGE_GOOD = "good/list",
  HOME_PAGE_GARANTY = "garanty/list",
  HOME_PAGE_ENTITYCONFIGURATION = "screnConfiguration/list",
  HOME_PAGE_USERS = "userAdmin/list",
}

export enum MenuRoles {
  ROLE_MENU_HOME = "ROLE_MENU_HOME",
  ROLE_MENU_OFFER = "ROLE_MENU_OFFER",
  ROLE_MENU_ORDER = "ROLE_MENU_ORDER",
  ROLE_MENU_THIRD = "ROLE_MENU_THIRD",
  ROLE_MENU_TASKS = "ROLE_MENU_TASKS",
  ROLE_MENU_SETTINGS = "ROLE_MENU_SETTINGS",
  ROLE_MENU_AVAILABILITY = "ROLE_MENU_AVAILABILITY",
  ROLE_MENU_SCALE = "ROLE_MENU_SCALE",
  ROLE_MENU_GOOD = "ROLE_MENU_GOOD",
  ROLE_MENU_GARANTY = "ROLE_MENU_GARANTY",
  ROLE_MENU_ENTITYCONFIGURATION = "ROLE_MENU_ENTITYCONFIGURATION",
  ROLE_MENU_ADMIN = "ROLE_MENU_ADMIN",
  ROLE_MENU_USERS = "ROLE_MENU_USERS",
  ROLE_MENU_SCREEN_AUTHORITY = "ROLE_MENU_SCREEN_AUTHORITY",
  ROLE_MENU_SUPERSET = "ROLE_MENU_SUPERSET",
  ROLE_MENU_PLANNIG = "ROLE_MENU_PLANNIG",
  ROLE_MENU_DASHBOARD = "ROLE_MENU_DASHBOARD",
  ROLE_MENU_GLOBAL_SETTINGS = "ROLE_MENU_GLOBAL_SETTINGS",
}

export interface ControlsState {
  errors: ODMControls[];
  warnings: ODMControls[];
  show: boolean;
  menuUiStatus: any;
  UiStatus: any;
  blockedMandatoryFields: any;
}

export interface ODMControls {
  control: string;
  message: string;
  block: boolean;
  category: string;
}

//Efficom Specification
export interface TableState {
  selectedColumns: any[];
  currentRows: any[];
  rowFilter?: any;
  selectedRows?: any;
}

export interface AddDialogState {
  // For example:
  show?: boolean;
  title?: string;
}
//layout
export enum colorClasses {
  CLASS_PRIMARY = "grey-6",
  CLASS_SECONDARY = "grey-1",
}

export enum styleClasses {
  CLASS_PRIMARY = "text-grey-6 text-justify",
  CLASS_SECONDARY = "text-white text-justify",
}

//Dossier
export interface DossierState {
  rowFilter?: any;
  openDossier?: boolean;
  selectedDossierdetails?: Dossier;
  mode?: string;
  dossiers?: Dossier[];
  isEditing?: boolean;
  unfilteredDossiers?: Dossier[];
  [key: string]: any;
}

export interface DosssierDetails extends UniformOdmName {
  Ref?: string;
  Cdtlouer?: string;
  Client?: Third;
  Vendeur?: Third;
  DateCommande?: string;
  DateDeLivraison?: string;
  dateRDVClient?: string;
  Status?: string;
  name?: string;
}

//Commande
export interface CommandState {
  openCommande: boolean;
  openFournisseur: boolean;
  selectedCommanddetails?: CommandDetails;
  mode?: string;
  vehicule?: Vehicule;
  listTab?: ListTab;
  listArticles?: any;
}

export interface CommandDetails extends UniformOdmName {
  Ref?: string;
  Cdtlouer?: string;
  Client?: string;
  vendeur?: any;
  DateDeCommande?: string;
  AdresseDeLivraison?: string;
  dateRDVClient?: string;
  Status?: string;
  Asset?: string;
  Fournisseur?: string;
  DpnnéesDuPVDesMines?: string;
}

export interface Vehicule {
  images?: Image[];
  energy?: string;
  power?: string;
  gearbox?: string;
  emission_CO2?: string;
  color?: string;
}

export interface Image {
  name?: string;
  Url?: string;
}

export interface ListTab {
  vehiculeTab?: VehiculeTab;
  optionTab?: OptionTab[];
  accessoryTab?: AccessoryTab[];
  fessTab?: FeesTab[];
}

export interface VehiculeTab {
  name?: string;
  price?: number;
  discount?: number;
  priceHT?: number;
  priceTTC?: number;
  refComm?: string;
}

export interface OptionTab {
  name?: string;
  price?: number;
  discount?: number;
  priceHT?: number;
  priceTTC?: number;
  refComm?: string;
}

export interface AccessoryTab {
  name?: string;
  price?: number;
  discount?: number;
  priceHT?: number;
  priceTTC?: number;
  refComm?: string;
}

export interface FeesTab {
  name?: string;
  price?: number;
  discount?: number;
  priceHT?: number;
  priceTTC?: number;
  refComm?: string;
}

export interface Dossier {
  id?: number;
  reference?: string;
  cdtLoueur?: string;
  client?: any;
  vendeur?: any;
  dateCommande?: string;
  dateRDVClient?: string;
  dateLivraison?: string;
  status?: ComboBoxItem;
  adresseLivraison?: string;
  conditionsDossier?: string[];
  prix?: number;
  prixHT?: number;
  remise?: number;
  prixTTC?: number;
  commandePrincipale?: Command;
  commandesFournisseurs?: Command[];
  tasks?: Task[]; //Weleaf
  documents?: any;
  detailsLivraison?: any;
  messages?: Message[];
  historique?: any;
}

export interface SelectedDossier extends Dossier {
  isEditing: boolean;
  isLoding: boolean;
}

export interface Command extends Dossier {
  asset: CommandAsset;
  type?: string;
  referenceCommande?: string;
}

export interface CommandAsset extends ProposalAsset {
  referenceCommandFournisseur: string;
  assetItems: CommandAssetItem[];
}

export interface CommandAssetItem extends ProposalAssetItem {
  referenceCommandFournisseur: string;
}

export interface selectedCommand extends Command {
  isEditing: boolean;
  isLoding: boolean;
}

// Dossier

export class DossierProgressStatus {
  icon: string;
  label: string;
  status: string;
  color: string;
  doneIcon?: string;
  constructor(
    icon: string,
    color: string,
    label: string,
    status: string,
    doneIcon?: string
  ) {
    this.icon = icon;
    this.label = label;
    this.status = status;
    this.color = color;
    this.doneIcon = doneIcon;
  }
}

export enum DossierStatus {
  COMMAND = "COMMAND",
  VALIDATION = "VALIDATION",
  SUPPLY = "SUPPLY",
  DELIVERY = "DELIVERY",
  ARCHIVING = "ARCHIVING",
}

export interface OrderState {
  changePreconditionReadOnly?: any;
  index: number;
  mode: OrderModeStatus;
  orders: any[];
  order?: any;
  orderItem?: any;
  asset?: any;
  historiesStep?: Task[];
  variablesVisible?: boolean;
  preConditionDetailVisible?: boolean;
  preConditionDetail?: any;
  statusHistoryVariableSelected?: OrderStatusHistoryVariables;
  orderFinancingModule: OrderFinancingState;
  preConditionValid?: any;
  selectedAssetFrom?: any;
  selectedProductAsset?: any;
  tempSelectedOrderGoods?: any;
  goodOrderFilter?: any;
  tempGoodOrderFilter?: any;
  gatheringProcessOrder?: any;
  assetDeliveryModel?: any;
  showAlert?: boolean;
  planningDelevry?: any;
  selectedEventPlanning?: any;
  selectedDayEvent?: any;
  selectedSearchOrder?: any;
  alertList?: any;
}

export interface OrderCommissionState {
  order?: any;
  orderCommissionResult?: any;
  orderCommissionResultTable?: Array<any>;
  commissionProductPack?: any;
  formBind: any;
  commission?: any;
}

export enum OrderModeStatus {
  CREATE = "CREATE",
  EDIT = "EDIT",
}

export interface Order extends UniformOdmName {
  config?: any;
  daaq?: string;
  reference: string;
  channel: string;
  previsionDeliveryDate?: string;
  effectiveDeliveryDate: string;
  assignedSalesPerson?: string;
  orderParties?: OrderAssociatedParty[];
  orderNetworks?: OrderNetwork;
  orderNetworkNodes?: OrderNetworkNode;
  status?: UniformOdmName;
  orderItems?: OrderItem[];
  financing?: OrderFinancing;
  orderCommissions?: any;
  alertList?: OrderAlert[];
  orderSubvention?: any[];
}

export interface OrderAlert {
  whoCreated?: string;
  whenCreated?: string;
  comment?: string;
  selectedMotifs?: string;
}
export interface OrderAssociatedParty extends UniformOdmName {
  orderParty?: OrderParty;
}

export interface OrderParty extends UniformOdmName {
  party?: UniformOdmName;
  role?: UniformOdmName;
  third?: Third;
}

export enum OrderPartyRoleEnum {
  SELLER = "SELLER",
  SUPPLIER = "SUPPLIER",
  GUARANTOR = "GUARANTOR",
  OTHER = "OTHER",
  CUSTOMER = "CUSTOMER",
  FINANCIER = "FINANCIER",
}

export interface OrderNetwork extends UniformOdmName {
  network?: UniformOdmName;
}

export interface OrderNetworkNode extends UniformOdmName {
  networkNode?: UniformOdmName;
}

export enum OrderStatusEnum {
  ORDER = "ORDER",
  VALIDATION = "VALIDATION",
  MANUFACTURING = "MANUFACTURING",
  TRANSFORMATION = "TRANSFORMATION",
  PREPARATION = "PREPARATION",
  DELIVERY = "DELIVERY",
  ARCHIVING = "ARCHIVING",
  ORDER_STARTED = "ORDER_STARTED",
  PLANNED = "PLANNED",
  DELIVERED = "DELIVERED",
  TO_PLAN = "TO_PLAN",
  TO_BE_RESCHEDULED = "TO_BE_RESCHEDULED",
}

export interface OrderItem extends UniformOdmName {
  reference: string;
  externalReference: string;
  initialDeliveryDate?: string;
  previsionDeliveryDate?: string;
  effectiveDeliveryDate: string;
  orderItemParent?: any;
  type?: UniformOdmName;
  status?: UniformOdmName;
  orderAssets?: OrderAsset[];
}

export enum OrderItemTypeEnum {
  CUSTOMER = "CUSTOMER",
  SUPPLIER = "SUPPLIER",
}

export enum OrderItemStatusEnum {
  DRAFT = "DRAFT",
  WAITING_FOR_VALIDATION = "WAITING_FOR_VALIDATION",
  VALIDATED = "VALIDATED",
  CANCELLED = "CANCELLED",
  DELIVERED = "DELIVERED",
}

export interface OrderAsset extends UniformOdmName {
  config?: any;
  description?: string;
  amountWoTax?: MoneyAmount;
  discountPercentage?: number;
  discountAmount?: MoneyAmount;
  taxAmount?: MoneyAmount;
  amountWTax?: MoneyAmount;
  quantity?: number;
  totalAmountWoTax?: MoneyAmount;
  totalTaxAmount?: MoneyAmount;
  totalAmountWTax?: MoneyAmount;
  assetProduct?: UniformOdmName;
  packAssetProduct?: UniformOdmName;
  supplier?: OrderParty;
  assetInventory?: UniformOdmName;
  orderAssetComponents?: OrderAssetComponent[];
  orderAssetServices?: any[];
  assetDelivery?: OrderAssetDelivery;
  purchaseAmountWoTax?: MoneyAmount;
  purchaseAmountWTax?: MoneyAmount;
  proposalAssetItems?: any;
  assetTradeIn?: any;
  flagNew?: any;
}

export interface OrderAssetComponent extends UniformOdmName {
  type?: OrderAssetComponentType;
  label?: string;
  amountWTax?: MoneyAmount;
  discountPercentage?: number;
  discountAmount?: MoneyAmount;
  taxAmount?: MoneyAmount;
  amountWoTax?: MoneyAmount;
  quantity?: number;
  totalAmountWoTax?: MoneyAmount;
  totalTaxAmount?: MoneyAmount;
  totalAmountWTax?: MoneyAmount;
  purchaseAmountWoTax?: MoneyAmount;
  purchaseAmountWTax?: MoneyAmount;
  config?: any;
}

export interface OrderAssetDelivery extends UniformOdmName {
  deliveryDate?: string;
  initialDeliveryDate?: string;
  previsionDeliveryDate?: string;
  effectiveDeliveryDate?: string;
  deliveryAddress?: UniformOdmName;
  process?: any;
  modality?: any;
  description?: string;
}

export type OrderAssetComponentType = UniformOdmName;

export interface OrderVehicle extends OrderAsset {
  flagNew?: boolean;
  nbOfCylinder?: number;
  nbOfGear?: number;
  taxHorsePower?: number;
  horsePower?: number;
  co2Emissions?: number;
  mineType?: number;
  fuelConsumptionUrban?: number;
  fuelConsumptionExtraUrban?: number;
  fuelConsumptionCombined?: number;
  brand?: string;
  range?: string;
  version?: string;
  carBodyStyle?: string;
  gearBox?: string;
  engineType?: string;
  matriculation?: string;
  matriculationDate?: string;
  chassisReference?: string;
  constructionDate?: string;
  currentMileage?: number;
  vehicleLength?: string;
  color?: string;
  proposalAssetItems?: any;

  firstCarRegistrationDate?: string;
  insideColor?: string;
}

//Analysis Module
export interface QuestionnaireGatheringItem {
  objectType: string;
  resourceUid: string;
  systemUid: string;
  businessData: any;
  daaq: string;
  questions: Array<{
    resourceUid: string;
    objectType: string;
    systemUid: string;
    businessData: any;
    daaq: string;
    question: {
      objectType: string;
      systemUid: string;
      resourceUid: string;
    };
    answer: {
      objectType: string;
      systemUid: string;
      resourceUid: string;
    };
  }>;
}

export interface Question {
  resourceUid: string;
  objectType: string;
  systemUid: string;
  businessData: any; // You might need to specify the correct type
  daaq: string;
  question: {
    objectType: string;
    systemUid: string;
    resourceUid: string;
  };
  answer: {
    objectType: string;
    systemUid: string;
    resourceUid: string;
  };
}

export interface AnswerOption {
  answer_option_order: string;
  answer_option_id: string;
  answer_option_i18n_code: string;
  question_id: string;
}

export interface Questionnaire {
  [key: string]: {
    [key: string]: {
      process_id: string;
      process_item_id: string;
      question_category_order: string;
      question_category_i18n_code: string;
      question_order: string;
      question_id: string;
      question_i18n_code: string;
      question_default_answer_code: string;
      answer_option_order: string;
      answer_option_id: string;
      answer_option_i18n_code: string;
      _daaq: string;
      answerOptions: AnswerOption[];
    };
  };
}

//Analysis Algorithme

export enum analysisTargetApi {
  CALCULATE_RATIO = "calculateRatio",
  EVALUATE_RATIO = "evaluateRatio",
  EVALUATE_RATING = "evaluateRating",
  EVALUATE_SCORE = "evaluateScore",
}

// #Ratio
export interface Ratio extends UniformOdmName {
  businessData?: any;
  daaq?: string;
  value?: string;
  ratioParam?: UniformOdmName;
  ratioEvaluations?: RatioEvaluation[];
}

export interface RatioEvaluation extends UniformOdmName {
  businessData?: any;
  daaq?: string;
  value?: string;
  type?: string;
  decision?: string;
  comment?: string;
}
export interface UserState {
  newCreatedUser?: boolean;
  mode?: string;
  selectedUserDetails?: LeaseForgeUser;
  team?: any;
  teamMembers?: any;
  userParty?: any;
  managers?: any;
  networkDetails?: any;
  manager?: any;
  temporaryPassword?: string;
  userLogs?: any;
  selectedAvatar?: any;
  isAvatarEdited?: boolean;
  avatarFileId?:any;
}

export interface RatingEvaluation extends UniformOdmName {
  businessData?: any;
  daaq?: string;
  calculatedValue?: string;
  updatedValue?: string;
  type?: string;
  decision?: string;
  comment?: string;
}

export enum adminAuthorities {
  ADMIN = "ROLE_ADMIN",
  SUPERADMIN = "ROLE_SUPERADMIN",
}

export interface RequiredDocumentType extends UniformOdmName {
  businessData?: string | undefined | null;
  daaq?: string;
  supportingType?: SupportingType[];
}

export interface SupportingType extends UniformOdmName {
  businessData?: string | undefined | null;
  daaq?: string;
  proofLevel?: number;
  manual?: boolean;
  supportingDocument?: SupportingDocument;
}

export type SupportingDocument = UniformOdmName;

export interface RequiredDocumentTypeResponse extends UniformOdmName {
  doc_type_id: string;
  supporting_type_id: string;
  supporting_document_code: string;
  manual: string;
  proof_level: string;
}

export interface SupportingDocManual {
  id?: any;
  typeJustify?: any;
  documentType?: any;
  document?: any;
  creationDate?: any;
  status?: any;
  comment?: any;
  processResourceUid?: any;
  fileContent?: any;
  targetEntity?: any;
}

export interface LoadCheckListEvent extends UniformOdmName {
  daaq?: string;
  businessData?: any;
  context?: Qualifier | string;
  contextId?: string;
  decision?: string;
  references?: string;
  status?: AnalysisProcessStatus;
  creationLabel?: string;
  assessmentType?: string;
  quoteInfo?: string;
  orderInfo?: string;
  partyInfo?: PartyInfo;
  assetInfo?: AssetInfo;
  accessoryProductInfo?: AccessoryProductInfo;
}

export interface OrderFinancingState {
  financing?: OrderFinancing;
}

export interface OrderFinancing extends UniformOdmName {
  offerReference?: string;
  label?: string;
  financedAmountWTax?: any;
  financedAmountWoTax?: any;
  financingProductPack?: UniformOdmName | null;
  scale?: string;
  contribution?: any;
  firstRent?: any;
  numberOfInsurances?: number;
  type?: ComboBoxItem;
  financingOrganization?: UniformOdmName;
  associatedParty?: OrderAssociatedParty;
  config?: any;
}
export interface OrderStatusHistoryVariables {
  orderReference?: string;
  orderStatus?: string;
  customerType?: string;
  customerName?: string;
  initialDeliveryDate?: string;
  provisionalDeliveryDate?: string;
  taskDefinition?: string;
  comment?: string;
}
export interface filterState {
  listOfFilters?: filter[];
  ShowFilter?: boolean;
  showEditMode?: boolean;
  isUpdateButton?: boolean;
  selectedFilter?: filter;
  isLoading?: boolean;
}
export interface filter extends UniformOdmName {
  description: string;
  hasPublicAccess?: boolean;
  numOrder: number;
  screenId?: string;
  count?: number;
  color?: string;
  businessData?: any;
}

export enum DecisionStatusEnum {
  ACCEPTED = "ACCEPTED",
  ACCEPTED_RESERVES = "ACCEPTED_RESERVES",
  REFUSED = "REFUSED",
}
export enum MessageToSendEnum {
  INPROGRESS = "changeStatusToInProgress",
  ONHOLD = "changeStatusToOnHold",
  CANCEL_Offer = "cancelOffer",
  CANCEL_Order = "cancelOrder",
  RETURN_TO_FRONT_OFFICE = "returnToFrontOffice",
  SIGNATURE_PROCESS_MESSAGE = "signatureProcessMessage",
}
export interface MessageToSend extends UniformOdmName {
  messageName: MessageToSendEnum;
  businessKey?: string;
}
export enum AdditionalActionEnum {
  CANCELLATION = "Cancellation",
  SEND_TO_FRONT_OFFICE = "Send_to_Front_Office",
  CANCEL_TRANSACTION = "Cancel_Transaction",
  RESTART_TRANSACTION = "Restart_Transaction",
  START_TRANSACTION = "Start_Transaction",
}

export interface DocumentTypeManual {
  id?: string;
  document_type_id?: string;
  supporting_document_code?: string;
  manual?: string;
  proof_level?: string;
}
export enum InventoryStatusEnum {
  RESERVED = "RESERVED",
  IN_STOCK = "IN_STOCK",
  SOLD = "SOLD",
}
export enum AssetTypeEnum {
  VEHICULE = "vehicle",
  EQUIPMENT = "equipment",
  BOAT = "boat",
}
export enum AssetFromEnum {
  CATALOG = "CATALOG",
  INVENTORY = "INVENTORY",
}

export enum SimulationAttributeEnum {
  DURATION = "duration",
  MILEAGE = "mileage",
  RESIDUAL_VALUE = "residualValue",
  FIRST_PAYMENT = "firstPayment",
  DOWN_PAYMENT = "downPayment",
  MONTHLY_PAYMENT = "monthlyPayment",
  PAYMENT = "payment",
}

export interface DeliveryScheduleSearch {
  profil?: ComboBoxItem;
  actor?: ComboBoxItem;
  type?: ComboBoxItem;
  step?: ComboBoxItem;
  status?: ComboBoxItem;
  day?: ComboBoxItem;
}

export const screenAuthorityKeys: { [key: string]: string } = {
  ROLE_MENU_HOME: "main.menu.home",
  ROLE_MENU_OFFER: "main.menu.myDemand",
  ROLE_MENU_ORDER: "main.menu.command",
  ROLE_MENU_THIRD: "main.menu.third",
  ROLE_MENU_TASKS: "main.menu.tasks",
  ROLE_MENU_SETTINGS: "main.menu.configuration",
  ROLE_MENU_AVAILABILITY: "main.menu.availability",
  ROLE_MENU_SCALE: "main.menu.scales",
  ROLE_MENU_GOOD: "sidebar.asset",
  ROLE_MENU_GARANTY: "sidebar.garanty",
  ROLE_MENU_USERS: "main.menu.user",
  ROLE_MENU_ADMIN: "main.menu.admin",
  ROLE_MENU_SCREEN_AUTHORITY: "main.menu.screen_authority",
  ROLE_MENU_SUPERSET: "main.menu.superset",
  ROLE_MENU_ENTITYCONFIGURATION: "main.menu.entity_configuration",
};

export interface RoleMenuState {
  roleMenus?: any[];
  selectedRoleMenu?: any;
  allUsers?: any[];
  allSecurityGroups?: any[];
  selectedUsers?: any[];
  selectedGroups?: any[];
  preExistingGroupsIDs?: string[];
  preExistingUsersIDs?: string[];
  preExistingGroups?: any[];
  preExistingUsers?: any[];
  newRoleName?: string;
  newRoleEN?: string;
  newRoleFR?: string;
}

export interface DashboardState {
  allDashboard?: any[];
  newExternalDashboard?: {};
  selectedExternalDashboard?: any;
  allUsers?: any[];
  selectedUsers?: any[];
  preExistingUsersIDs?: string[];
  preExistingUsers?: any[];
}
export enum Languages {
  FR = "FR",
  EN = "EN",
}

export interface SuperSetState {
  supersets?: any[];
  existingSupersets?: any[];
}

export interface KSIOPFinancialExposure extends UniformOdmName {
  firstName?: string;
  familyName?: string;
  birthDate?: string;
  siret?: string;
  financialExposure: financialExposures;
  coverage?: number;
  priceWTax?: MoneyAmount;
}
export enum ServiceTypes {
  SimpleServiceProduct = "simpleserviceproduct",
  MaintenanceProduct = "maintenanceproduct",
  PartyInsuranceProduct = "partyinsuranceproduct",
  AssetInsuranceProduct = "assetinsuranceproduct",
  OriginationCommissionProduct = "originationcommissionproduct",
}

export const unreadValues = [
  "positionType",
  "currentMaritalStatus",
  "activitySector",
  "currentMatrimonialRegime",
  "activity",
  "partyClass"
];


export enum proposalAccessoriesObjectTypes  {
  proposalPartyInsuranceObjectType = "odm.offer.proposalaccessories.proposalinsurance.proposalpartyinsurance",
  proposalsimpleservice = "odm.offer.proposalaccessories.proposalsimpleservice",
  simpleServiceProductItem = "odm.product.financingproductpackitem.accessoryproductitem.serviceproductitem.simpleserviceproductitem"
};
export enum businessDataUtils {
  offerApiSource = "External"
};

export enum paymentDatesOptions {
  INSTALLMENT = "INSTALLMENT"
}


export enum dataTypeAttributeEnum {
  BOOLEAN = "BOOLEAN"
}
export interface AssetImage {
  resourceUid: string;
  objectType: string;
  systemUid: string;
  businessData: any; 
  daaq: string;
  originalFileName: string;
  format: string;
  md5Hash: string;
  metadata: Metadata[];
  relations: Relation[];
  content: string;
}

