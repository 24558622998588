import {
    buildProposalFromSelectedScale,
    getAccessories,
    getHelperFromScale,
    getHelperFromState,
} from "@/store/services/financing/financingMapper";
import {
    deepClone,
    deleteAttributesRecursively,
    deleteNonRequiredAttributes,
    formatDateRecursively,
    removeAttributesRecursively
} from "@/utils";
import {
    getAgeEndOfContract,
    getContractDuration,
    getFinancedValue,
    verifyPartyInsured
} from "@/store/services/financing/financingService";
import {
    AccessoryProductInfo,
    Offer,
    OfferAssociatedParty,
    Proposal,
    RootState,
    SearchVariableType,
    ThirdType
} from "@/types";
import {calculateSimulation} from "@/store/services/simulation";
import {completeSimpleFinancing, evaluateAllPartyInsuranceAccessory} from "@/store/services/financing/product";
import {PartyAssociated} from "@/store/services/offerService";

export const calculatePartyInsurance = async (state: RootState) => {
    const scale = state.financingModule.scalesModule.scaleSelected
    const financingOfferItem = scale?.items.filter((s: any) => s.objectType === 'odm.product.financingproductpackitem.financingofferitem')[0]
    if (scale && financingOfferItem) {
        const servicePartyInsurance: any = deepClone(scale.scalePresentation.servicePartyInsurance)
        const {
            partyInfo,
            assetInfo,
            quoteInfo
        } = await getEvaluateAllPartyInsuranceAccessoryBody(state)
        const result = await calculateSimulation({
            searchVariable: SearchVariableType.payment,
            financialProductUid: {
                resourceUid: scale.resourceUid,
                systemUid: 'odm-product'
            },
            productUid: {
                resourceUid: financingOfferItem.resourceUid,
                systemUid: 'odm-product'
            },
            quoteInfo,
            assetInfo,
            partyInfo,
            accessories: getAccessories(scale, servicePartyInsurance)
        })
        return mergeBeneficiariesDataResult(result, servicePartyInsurance)
    }

}

const getEvaluateAllPartyInsuranceAccessoryBody = async (state: RootState) => {
    const helper: any = await getHelperFromState(state)
    helper.scaleSelected = state.financingModule.scalesModule.scaleSelected
    const proposalList: Proposal[] = await buildProposalFromSelectedScale(state, helper)
    const proposal = deepClone(proposalList[0])
    proposal.proposalItems.forEach((el:any) => {
        for (const key in el) {
            if (el[key] === undefined){
                el[key] = null
            }
        }
        el.contractDuration = getContractDuration(el)
        el.financedValue = getFinancedValue(el, state, helper.scaleSelected)
    })
    const partyInfo = deepClone((state.demandModule?.offer?.associatedParties || [])).map((offerAssociatedParty: any) => {
        const {role_code} = offerAssociatedParty
        const {third} = offerAssociatedParty.associatedParty
        const type = third.type.id
        const body = type.includes("Organization") ? { ...third.organization } : { ...third.person }
        removeAttributesRecursively(body)
        deleteAttributesRecursively(body, ['isDisabled'])
        body.ageEndOfContract = getAgeEndOfContract(proposal.proposalItems[0], body)
        body.flagInsured = verifyPartyInsured(offerAssociatedParty)
        return {
            partyData: JSON.stringify({
                type,
                role: role_code || 'CLIENT',
                ...body
            })
        }
    })
    const assetList = proposal.proposalItems
    const assetInfo = assetList.map((asset: any) => {
        removeAttributesRecursively(asset)
        return {
            assetData: JSON.stringify(asset)
        }
    })
    const quoteInfo = JSON.stringify({
        ...proposal
    })
    return {
        proposal,
        partyInfo,
        assetInfo,
        quoteInfo
    }
}

const getEvaluateAllPartyInsuranceAccessoryBodyFromScale = async (state: RootState, scaleParameter: any) => {
    const helper: any = await getHelperFromScale(state, scaleParameter)
    helper.scaleSelected = scaleParameter
    const proposalList: Proposal[] = await buildProposalFromSelectedScale(state, helper)
    const proposal = deepClone(proposalList[0])
    proposal.proposalItems.forEach((el:any) => {
        for (const key in el) {
            if (el[key] === undefined){
                el[key] = null
            }
        }
        el.contractDuration = getContractDuration(el)
        el.financedValue = getFinancedValue(el, state, helper.scaleSelected)
    })
    const partyInfo = deepClone((state.demandModule?.offer?.associatedParties || [])).map((offerAssociatedParty: any) => {
        const {role_code} = offerAssociatedParty
        const {third} = offerAssociatedParty.associatedParty
        const type = third.type.id
        const body = type.includes("Organization") ? { ...third.organization } : { ...third.person }
        removeAttributesRecursively(body)
        deleteAttributesRecursively(body, ['isDisabled'])
        body.ageEndOfContract = getAgeEndOfContract(proposal.proposalItems[0], body)
        body.flagInsured = verifyPartyInsured(offerAssociatedParty)
        return {
            partyData: JSON.stringify({
                type,
                role: role_code || 'CLIENT',
                ...body
            })
        }
    })
    const assetList = proposal.proposalItems
    const assetInfo = assetList.map((asset: any) => {
        removeAttributesRecursively(asset)
        return {
            assetData: JSON.stringify(asset)
        }
    })
    const quoteInfo = JSON.stringify({
        ...proposal
    })
    return {
        proposal,
        partyInfo,
        assetInfo,
        quoteInfo
    }
}


export const calculateBeneficiaryAmount = (beneficiary: any) => {
    let amountTTC = 0
    let amountHT = 0
    if (!beneficiary.amount) {
        return { amountHT, amountTTC }
    }
    const tax: number = beneficiary.rate ? Number(beneficiary.rate) : 0;
    if (typeof beneficiary.amount === 'object' ) {
        amountHT = beneficiary.amount.amount || 0
        amountTTC = amountHT * (1 + tax)
    } else {
        amountHT = beneficiary.amount
        amountTTC = amountHT * (1 + tax)
    }
    return { amountHT, amountTTC }
}

export const updatePartyInsurance = (calculatedPartyInsuranceList: any, partyInsuranceList: any) => {
    for (const partyInsuranceItem of calculatedPartyInsuranceList) {
        const resourceUid = partyInsuranceItem.resourceUid || partyInsuranceItem.reference
        const partyInsuranceSate = partyInsuranceList.filter((item: any) => (item.resourceUid || item.reference || item.productUid) === resourceUid)[0]
        if (partyInsuranceSate) {
            const {amountValueWoTax, amountValueWTax} = partyInsuranceItem
            partyInsuranceSate._controls = {
                amountValueWoTax, amountValueWTax, selected: true
            }
            const beneficiaries = partyInsuranceSate.beneficiaries.filter((item: any) => !item._controls.selected)
            
            partyInsuranceSate.beneficiaries = partyInsuranceItem.details.map((item: any) => {
                formatDateRecursively(item, 'DD/MM/YYYY', 'YYYY-MM-DD')
                return {
                    objectType: item.objectType,
                    systemUid: item.systemUid,
                    partyUid: item.partyUid,
                    partyName: item.partyName,
                    partyFirstName: item.partyFirstName,
                    partyFamilyName: item.partyFamilyName,
                    partyBirthDate: item.partyBirthDate,
                    partyRole: item.partyRole,
                    amountWoTax: item.amountWoTax,
                    amountWTax: item.amountWTax,
                    rate: item.rate,
                    coverage: item.coverage,
                    basis: item.basis,
                    _controls: {
                        selected: true
                    }
                }
            })
            beneficiaries.forEach((item: any)  => {
                partyInsuranceSate.beneficiaries.push(item)
                
            });
        }
    }

}

export const reevaluatePartyInsurance = async (state: RootState, scaleParameter?: any) => {
    const scale = scaleParameter || state.financingModule.scalesModule.scaleSelected
    if (scale && scale.resourceUid) {
        const financingOfferItem = scale.items.filter((i: any) => i.objectType === 'odm.product.financingproductpackitem.financingofferitem')[0]
        if (financingOfferItem ) {
            const {
                partyInfo,
                assetInfo,
                quoteInfo,
                proposal
            } = (!scaleParameter ? await getEvaluateAllPartyInsuranceAccessoryBody(state) : await getEvaluateAllPartyInsuranceAccessoryBodyFromScale(state, scaleParameter))
            const proposalItem: any = deepClone(proposal.proposalItems[0])
            const financedValue = proposalItem.financedValue
            proposalItem.financedValue = null
            proposalItem.price = financedValue
            const proposalAccessories = scale.scalePresentation.servicePartyInsurance && scale.scalePresentation.servicePartyInsurance.length
                ? deepClone(scale.scalePresentation.servicePartyInsurance) : []
            const accessoryProductInfo = proposalAccessories.map((o: any) => mapProposalAccessories(o))[0]
            const productSelectionParameter = {
                applicationName: "ORIGINATION",
                context: "",
                maxResults: 100,
                partyInfo: partyInfo,
                assetInfo: assetInfo,
                accessoryProductInfo,
                quoteInfo
            }
            const completeSimpleFinancingResponse = await completeSimpleFinancing(financingOfferItem.resourceUid, productSelectionParameter)
            const {annualRate} = completeSimpleFinancingResponse && completeSimpleFinancingResponse.length ? completeSimpleFinancingResponse[0] : 0
            if (annualRate) {
                accessoryProductInfo.forEach((accessory: any) => {
                    accessory.annualRate = annualRate
                })
            }
            const partyInsuranceResult = await evaluateAllPartyInsuranceAccessory(scale.resourceUid, productSelectionParameter, state)
            mergeBeneficiariesData(partyInsuranceResult, scale)
            return partyInsuranceResult
        }
    }
}


export const mapProposalAccessories = (servicePartyInsurance: any): AccessoryProductInfo => {
    const {calculationMethodOption, paymentDatesOption} = servicePartyInsurance
    return servicePartyInsurance.beneficiaries
        .filter((item: any) => item._controls?.selected)
        .map((item: any) => {
            const currency = item.amount ? item.amount.currency : 'EUR'
            return {
                currency,
                quantity: item.coverage,
                calculationMethodOption: calculationMethodOption,
                paymentDatesOption: paymentDatesOption,
                basis:  item.basis,
                amount: item.amount,
                annualRate: item.rate
            }
        })
}

export const mergeBeneficiariesData = (partyInsuranceResult: any, scale: any) => {
    partyInsuranceResult.forEach((result: any) => {
        result.beneficiaries.forEach((resultBeneficiary: any) => {
            (scale.scalePresentation.servicePartyInsurance || [])
                .forEach((o: any) => {
                    const beneficiary = o.beneficiaries.filter((o: any) => o.partyUid === resultBeneficiary.partyUid)[0]
                    if (beneficiary && beneficiary.coverage) {
                        resultBeneficiary.coverage = beneficiary.coverage
                        resultBeneficiary._controls = beneficiary._controls
                    }
                })
        })
    })
}


const mergeBeneficiariesDataResult = (calculationResult: any, servicePartyInsurance: any) => {
    const partyInsuranceResult: any = calculationResult?.paymentSchedules[1].items
        .filter((item: any) => item.objectType === 'odm.simulation.paymentscheduleitem.accessorypaymentscheduleitem.partyinsurancepaymentscheduleitem')

    servicePartyInsurance.forEach((item: any) => {
        const resourceUid = item.resourceUid || item.productUid
        const partyInsuranceResponse = partyInsuranceResult.filter((item: any) => (item.resourceUid || item.reference || item.productUid) === resourceUid)[0]
        if (partyInsuranceResponse) {
            const {amountValueWoTax, amountValueWTax} = partyInsuranceResponse
            item._controls = {
                amountValueWoTax, amountValueWTax, selected: item?._controls?.selected || true
            }
            item.beneficiaries.forEach((beneficiary: any) => {
                const detail = partyInsuranceResponse.details.filter((detailItem: any) => detailItem.partyUid === beneficiary.partyUid)[0]
                if (detail) {
                    beneficiary.amountWoTax = detail.amountWoTax
                    beneficiary.amountWTax = detail.amountWTax
                }
            })
        }
    })

    return servicePartyInsurance
}

const buildPartyAssociationsMap = async (simpleQuote: any) => {
    const result: any = []
    for (const proposalAccessory of simpleQuote.proposalAccessories) {
        if(proposalAccessory.associatedInsuranceBeneficiaries){
            for (const proposalPartyInsurance of proposalAccessory.associatedInsuranceBeneficiaries) {
                const partyAssociated = await PartyAssociated(proposalPartyInsurance.associatedParty.resourceUid)
                if(partyAssociated) {
                    result.push({
                        proposalPartyInsuranceAssociatedPartyUid: proposalPartyInsurance.associatedParty.resourceUid,
                        ...partyAssociated
                    })
                }
    
            }
        }   
    }
    return result
}

export const mergeBeneficiariesDataResultEdit = async (calculationResult: any, servicePartyInsurance: any, proposal: Proposal, offer: any) => {
    const result: any = []
    const simpleQuote = proposal.proposalItems[0]
    const partyAssociationsMap: any = await buildPartyAssociationsMap(simpleQuote)
    const partyInsuranceResult: any = calculationResult?.paymentSchedules[1].items
        .filter((item: any) => item.objectType === 'odm.simulation.paymentscheduleitem.accessorypaymentscheduleitem.partyinsurancepaymentscheduleitem')
    partyInsuranceResult.forEach((item: any) => {
        item._controls = item._controls || { selected: false }
        const resourceUid = item.resourceUid || item.productUid || item.reference
        const partyInsuranceOffer = simpleQuote.proposalAccessories.filter((i: any) => i.accessoryProduct.resourceUid === resourceUid)[0]
        if (partyInsuranceOffer) {
            item._controls.selected = true
            item.details.forEach((beneficiary: any) => {
                const associatedParty = partyAssociationsMap.filter((data: any) => beneficiary.partyUid === data.proposalPartyInsuranceAssociatedPartyUid || beneficiary.partyUid === data.party.resourceUid)[0]
                beneficiary._controls = beneficiary._controls || {}
                beneficiary._controls.selected = !!associatedParty
                beneficiary._controls.associatedParty = associatedParty
            })
        } else {
            item.details.forEach((beneficiary: any) => {
                beneficiary._controls = beneficiary._controls || {}
                beneficiary._controls.selected = false
            })
        }
        item._controls.amountValueWoTax = item.amountValueWoTax
        item._controls.amountValueWTax = item.amountValueWTax
        item.beneficiaries = item.details
        const partyInsuranceItem = servicePartyInsurance.filter((i: any) => (i.resourceUid || i.reference || i.productUid) === resourceUid)[0]
        if (partyInsuranceItem) {
            item.resourceUid = partyInsuranceItem.resourceUid
            item.objectType = partyInsuranceItem.objectType
            item.productUid = partyInsuranceItem.productUid
            item.currency = partyInsuranceItem.currency
            item.amount = partyInsuranceItem.amount
            item.flagIncludingAPRC = partyInsuranceItem.flagIncludingAPRC
            item.paymentDatesOption = partyInsuranceItem.paymentDatesOption
            item.calculationMethodOption = partyInsuranceItem.calculationMethodOption
            item.annualRate = partyInsuranceItem.annualRate
            item.basis = partyInsuranceItem.basis
        }
        result.push(item)
    })
    return result
}
