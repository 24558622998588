
import { Options, Vue } from "vue-class-component";
import store from "@/store";
import router from "@/router";
import { getTranslations, treatLocale } from "@/i18n.service.handler";
@Options({
  props: ["preview"],
})

export default class profile extends Vue {
  language = "";
  preview: any;
  i18nTranslations = getTranslations;
  treatLocale = treatLocale;
  get user() {
    return store?.state?.authModule?.user;
  }
  get languageOptions() {
    return store.getters["authModule/getUserConnected"].preferredLanguages
  }

  UserPreference() {
    store.dispatch("userModule/setMode", "edit");
    router.push("/profile");
  }
}
